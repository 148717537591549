import React, { useEffect, useState } from "react";
import logo from "../../Assets/Logo.png";
import Loading from "./Loading";
const StartLoad = () => {
  return (
    <div
      className="d-flex justify-content-center  ling-items-center"
      style={{ height: "80vh", backgroundColor: "white" }}
    >
      <div className="align-self-center">
        {
          <img
            src={logo}
            alt="asd"
            className="img-fluid"
            style={{ maxWidth: "150px" }}
          />
        }
        <Loading />
      </div>
    </div>
  );
};

export default StartLoad;
