import React, { useState, useEffect } from "react";
import CircularWithValueLabel from "./CircularLabel";

const CuentaRegresiva = ({fecha}) => {
  const targetDate = new Date(fecha); // Fecha objetivo para la cuenta regresiva
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  // Calcula el tiempo restante en días, horas y minutos
  function calculateTimeRemaining() {
    const currentTime = new Date();
    const difference = targetDate - currentTime;

    if (difference > 0) {
        const seconds = Math.floor(difference / 1000) % 60;
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / (1000 * 60)) % 60);
      return { days, hours, minutes , seconds};
    } else {
      return { days: 0, hours: 0, minutes: 0 };
    }
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="container">
      <h1 className="text-success fw-bolder text-center mt-5 mb-4">
        Tiempo restante para el sorteo
      </h1>

      <div className="d-flex justify-content-evenly flex-wrap w-100 mt-5">
        <div className="d-flex justify-content-center flex-column card p-1 p-sm-5 mb-3 shadow">
          <CircularWithValueLabel
            progres={(timeRemaining.days * 100) / 365}
            value={timeRemaining.days}
          />
          <h4 className="fw-bold  mt-3 text-center">Días</h4>
        </div>

        <div className="d-flex justify-content-center flex-column card p-1 p-sm-5 mb-3 shadow">
          <CircularWithValueLabel
            progres={(timeRemaining.hours * 100) / 29}
            value={timeRemaining.hours}
          />
          <h4 className="fw-bold  mt-3 text-center">Horas</h4>
        </div>

 <div className="d-flex justify-content-center flex-column card p-1 p-sm-5 mb-3 shadow">
          <CircularWithValueLabel
            progres={(timeRemaining.minutes * 100) / 60}
            value={timeRemaining.minutes}
          />
          <h4 className="fw-bold  mt-3 text-center">Minutos</h4>
        </div>

        
 <div className="d-flex justify-content-center flex-column card p-1 p-sm-5 mb-3 shadow">
          <CircularWithValueLabel
            progres={(timeRemaining.seconds * 100) / 60}
            value={timeRemaining.seconds}
          />
          <h4 className="fw-bold  mt-3 text-center">Segundos</h4>
        </div>

      </div>
 
    </div>
  );
};

export default CuentaRegresiva;
