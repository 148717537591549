

const Aviso = ()=>{
 return (<>
 <div className="w-100 d-flex justify-content-center ">
    <div className="card shadow p-3 border-danger" style={{maxWidth:"300px"}}>
<h2 className="fw-bolder text-danger">Aviso</h2>
<h5>Recuerda añadir en el concepto de la transferencia tu nombre completo</h5>
<h6 className="text-muted">No te quedes fuera, deja que la suerte te haga ganar</h6>
          </div>
</div>
 </>)
    
}
export default Aviso