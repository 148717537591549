import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState } from "react";
import BACKEND_URL from "../../Contextos/backend";
import { setLocalStorage, setLocalStorageJWT } from "../../Contextos/Storage";
import logo from "../../../Assets/Logo.png";
import { Password, PasswordRounded } from "@mui/icons-material";
import { Alert, Backdrop, CircularProgress } from "@mui/material";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://solucionesfelix.com/">
        Soluciones Felix
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function FormLogin() {
  const [Values, setValues] = useState({
    Correo: "",
    Contraseña: "",
  });
  const [openLoading, setOpenLoading] = useState(false);
  const [CorreoError, setCorreoError] = useState(false);
  const [ContraseñaError, setContraseñaError] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [open, setOpen] = useState(false);

  const handlInputChange = ({ target }) => {
    setValues({
      ...Values,
      [target.name]: target.value,
    });

    if (!Values.Correo || Values.Correo == "") {
      setCorreoError(true);
    } else {
      setCorreoError(false);
    }
    if (!Values.Contraseña || Values.Contraseña == "") {
      setContraseñaError(true);
      return;
    } else {
      setContraseñaError(false);
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      clicLogin();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const clicLogin = () => {
    let error = false;
    if (!Values.Correo || Values.Correo == "") {
      setCorreoError(true);
      error = true;
    }
    if (!Values.Contraseña || Values.Contraseña == "") {
      setContraseñaError(true);
      error = true;
    }

    if (!error) {
      setOpenLoading(true);
      const info = {
        correo: Values.Correo,
        password: Values.Contraseña,
      };
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(info),
      };
      fetch(BACKEND_URL + "usuarios/validarAcceso", requestOptions)
        .then((response) => response.json())
        .then((response) => {
          console.log(response);
          setOpenLoading(false);
          if (response.estatus == "OK") {
            let RES = JSON.parse(response.objRespuesta);
            setLocalStorageJWT(response.respuesta);
            setLocalStorage("NOMBRE", RES.NOMBRE);
            setLocalStorage("TIPO", RES.TIPO);
            setLocalStorage("CORREO", RES.CORREO);
            window.location.reload();
          } else {
            setMensaje("Acceso incorrecto, verifica tus credenciales");
            setOpen(true);
          }
        })
        .catch((error) => {
          setOpenLoading(false);
          setMensaje(
            "Ha ocurrido un error al conectar con nuestros servidores, intenta mas tarde."
          );
          setOpen(true);
          return;
        });
    }
  };

  React.useEffect(() => {
    document.title = `Iniciar sesión - Panel administrador`;
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      {openLoading && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <div className="d-flex justify-content-center align-content-center">
            <CircularProgress color="inherit" />
            <div className="ms-3 align-self-center">
              <h4 className="mb-0 ">Cargando...</h4>
              <h6 className="mb-0 ">🔒 Validando acceso </h6>
            </div>
          </div>
        </Backdrop>
      )}
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <center>
            <img
              src={logo}
              className="img-fluid mb-3"
              style={{ maxWidth: "200px" }}
            />
          </center>
          <Typography component="h1" variant="h5">
            Panel administrador
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            {open && (
              <Alert variant="filled" severity="error">
                {mensaje}
              </Alert>
            )}

            <TextField
              color="success"
              margin="normal"
              required
              fullWidth
              id="Correo"
              name="Correo"
              label="Usuario"
              autoComplete="usuario"
              value={Values.Correo}
              error={CorreoError}
              onChange={handlInputChange}
              autoFocus
            />
            <TextField
              color="success"
              margin="normal"
              required
              fullWidth
              id="Contraseña"
              name="Contraseña"
              label="Contraseña"
              type="password"
              value={Values.Contraseña}
              onKeyDown={handleKeyDown}
              onChange={handlInputChange}
              error={ContraseñaError}
              autoComplete="current-password"
            />

            <Button
              className="py-2"
              style={{ borderRadius: "13px", height: "50px" }}
              onClick={() => {
                clicLogin();
              }}
              fullWidth
              variant="contained"
              color="success"
              sx={{ mt: 2, mb: 2 }}
              disabled={openLoading}
            >
              Iniciar sesión
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 2, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
