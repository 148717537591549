import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.css';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Avatar, Backdrop, CircularProgress, ListItemIcon, ListItemText } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DialogoUsuario from "../Dialogos/DialogoSorteos";
import Confirmar from "../../Includes/Dialogos/Confirmar";
import { Activo, Eliminar } from "../Funciones/Sorteos";
import BurstModeIcon from '@mui/icons-material/BurstMode';
import DialogoImagenes from "../Dialogos/DialogoImagenes";
import Switch from "../../Includes/Switch";

export default function Row({ row, getListaAdmin }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogoImagenes, setOpenDialogImagenen] = useState(false);
  const [openLoading, setOpenLoading] = useState(false); 
  const [openConfirmElimnar, setOpenConfirmElimnar] = useState(false);
  const [id, setID] = useState(0);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const EliminarRegistro = (id) => {
    setOpenLoading(true)
    Eliminar(id)
      .then((resp) => {
        iziToast.success({
          title: 'OK',
          message: resp.mensaje,
          position: 'topCenter',
        }); 
        setOpenLoading(false)
        getListaAdmin();
      })
      .catch((resp) => {
        iziToast.error({
          title: 'Error',
          message: resp.mensaje,
          position: 'topCenter',
        });
        setOpenLoading(false)  
      });
  };


  const selectActivo = (id) => {
    setOpenLoading(true)
    Activo(id)
      .then((resp) => {
        iziToast.success({
          title: 'OK',
          message: resp.mensaje,
          position: 'topCenter',
        }); 
        setOpenLoading(false)
        getListaAdmin();
      })
      .catch((resp) => {
        iziToast.error({
          title: 'Error',
          message: resp.mensaje,
          position: 'topCenter',
        });
        setOpenLoading(false)  
      });
  };
  return (
    <>
 
      {openDialog && (
        <DialogoUsuario
          setOpen={setOpenDialog}
          id={id}
          getListaAdmin={getListaAdmin}
          
        />
      )}

      
      {openDialogoImagenes && (
        <DialogoImagenes
          setOpen={setOpenDialogImagenen}
          id={id}  
        />
      )}

      {openConfirmElimnar && (
        <Confirmar
          Titulo={"¿Estas seguro de emininar?"}
          TextoAyuda="Esta acción no se puede deshacer"
          funcionEjecutar={() => {
            EliminarRegistro(row.ID);
          }}
          setOpen={setOpenConfirmElimnar}
          openLoading={openLoading}
        />
      )}

     
      <TableRow
        key={row.ID}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell align="left" width={40}>
          <div className="d-flex">
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  setID(row.ID);
                  setOpenDialog(true);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <EditOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Editar</ListItemText>
              </MenuItem>
              {row.ID!=1 &&row.ID!=2 &&
              <MenuItem
                onClick={() => {
                  setID(row.ID);
                  setOpenConfirmElimnar(true);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <DeleteOutlineOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Eliminar</ListItemText>
              </MenuItem>}

   <MenuItem
                onClick={() => {
                  setID(row.ID);
                  setOpenDialogImagenen(true);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <BurstModeIcon />
                </ListItemIcon>
                <ListItemText>Cargar imagenes</ListItemText>
              </MenuItem>
              
            </Menu>


            <Switch
                          checked={row.ACTIVO}
                          onChange={()=>{}}
                          onClick={
                            ()=>
                            {
                              selectActivo(row.ID)
                            }
                          }
                          id={row.ID}
                          tituloActivo="Desactivar administrador "
                          tituloInactivo="Activar administrador"
                        />
          </div>
        </TableCell>
         
        <TableCell align="left" component="th" scope="row">
             #{row.ID}
        </TableCell>
        <TableCell className=" " align="left">
          {row.NOMBRE}
        </TableCell>
        <TableCell className=" " align="left">
          {row.FECHA_FIN}
        </TableCell>
        <TableCell className=" " align="left">
          {row.PRECIO_BOLETO}
        </TableCell>
          <TableCell className=" " align="right">
          {row.MAX_BOLETOS}
        </TableCell>
      </TableRow>
    </>
  );
}
