import { stringify } from "query-string";
import { getData, postdData, postUrl } from "../admin/Contextos/backend";
import validator from "validator";
import dayjs from "dayjs";

///////////////////
/* FUNCIONES GET */
///////////////////
export const getSorteoActivo = async () => {
  let data = [];
  let queryParamsObj = {};
  const url = `sorteos/activo`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data;
      resolve(data);
    } else {
      reject(res);
    }
  });
};


export const getBoletosDisponibles = async (id, pagina) => {
  let data = [];
  let queryParamsObj = {};
  const url = `sorteos/getBoletos?id=${id}&pagina=${pagina}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data;
      resolve(data);
    } else {
      reject(res);
    }
  });
};


export const getInfoBoleto = async (folio) => {
  let data = [];
  let queryParamsObj = {};
  const url = `ventas/getByFolio?folio=${folio}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data;
      resolve(data);
    } else {
      reject(res);
    }
  });
};
const validateEmail = (email) => {
  const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; 
  return regex.test(email);
};

export const getInfoBoletoDis = async (folio) => {
  let data = [];
  let queryParamsObj = {};
  const url = `ventas/foliiosdisp?folio=${folio}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data;
      resolve(data);
    } else {
      reject(res);
    }
  });
};
 
export const getBoletosAleatorios = async (numero) => {
  let data = [];
  let queryParamsObj = {};
  const url = `sorteos/getRuleta?NUMERO=${numero}`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) {
      data = res.data;
      resolve(data);
    } else {
      reject(res);
    }
  });
};
 
////////////////////
/* FUNCIONES POST */
////////////////////
export const guardarApartado = async (Values, Errores, setErrores, activeButtons) => {
  let error = false;

  /* REQUERIDOS */
  if (!Values.nombre || Values.nombre == "") {
    let Errores2 = Errores;
    Errores2.nombre = true;
    setErrores(Errores2);
    error = true;
  }
 

  if (!Values.telefono || Values.telefono == "") { 
    Errores.telefono = true;
    setErrores(Errores);
    error = true;
  } else {
  if (Values.telefono.toString().length != 10) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    }

  }




  if (!Values.apellidos || Values.apellidos == "") {
    Errores.apellidos = true;
    setErrores(Errores);
    error = true;
  }

  if (!Values.municipio || Values.municipio == "") {
   // Errores.municipio = true;
   // setErrores(Errores);
   // error = true;
  }

  console.log(error) 

  if (!Values.estado || Values.estado == "") {
    Errores.estado = true;
    setErrores(Errores);
    error = true;
  }
  console.log(error)
  if (Values.correo != "") { 
    if (!validateEmail(Values.correo)) { 
      Errores.correo = true;
      setErrores(Errores);
      error = true;
    }
  }

  console.log(Values)
 
  if (!error) {
    let data = [];
    Values.boletos =activeButtons;
    const body = Values;
    console.log("Datos", body);
    const url =  "ventas/Insertar";
    const res = await postdData(url, body);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};


