import React, { createContext, useState } from 'react';

// Crea el contexto
export const AppContext = createContext();

// Crea el componente proveedor del contexto
export const AppProvider = ({ children }) => {
  const [arrayData, setArrayData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Función para modificar el array
  const updateArrayData = (newData) => {
    setArrayData(newData);
  };

  // Define el valor del contexto
  const contextValue = {
    arrayData,
    updateArrayData,
    isLoading,
    setIsLoading
  };

  // Retorna el componente proveedor con el valor del contexto
  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};
