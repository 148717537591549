import React from 'react';
import ReactDOM from 'react-dom/client'; 
import App from './App'; 
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Index from './admin/Index';
import P404 from './404';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( 
      <Router>
      <Routes>
        <Route path="/*" element={<App />}></Route> 
        <Route path="/admin/*" element={<Index />} />
        <Route path="/*" element={<P404 />} />
      </Routes>
    </Router>
  
);
 