import { TituloContext } from "./../Contextos/TituloContext";
import { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Lista from "./Componentes/Lista"

export default function UsuariosIndex() {
  const { actualizarTitulo } = useContext(TituloContext);

  useEffect(() => {
    actualizarTitulo("Usuarios");
    document.title = "Usuarios - Sorteos RM";
  }, []);

  return (
    <>
      <div className="container-fluid p-0 p-sm-2  py-3">
      <Routes>
                  <Route path="/" element={<Lista />}></Route>
                  
                </Routes>
      </div>
    </>
  );
}
