import logo from "../Assets/facebook.png";

const Redes = () => {
  return (
    <>
    <a href="https://www.facebook.com/profile.php?id=100088642774433" 
    className="text-success"
    
    target="_blank">  
      <div className="d-flex justify-content-center w-100 mt-5">
        <div className="d-flex ">
          <div>
            <img
              src={logo}
              style={{ maxWidth: "50px" }}
              className="img-fluid "
            />
          </div>
          <h4 className="mb-0 align-self-center ms-3">RM Sorteos Jalisco </h4>
        </div>
      </div>
      </a>
    </>
  );
};

export default Redes;
