import logo from "../Assets/Logo.png";
import solucionesfelix from "../Assets/solucionesfelix.png";

export default function Footer ()
{

return (<>
<div className="container-fluid" style={{backgroundColor:"#e7ffe7"}}>
<div className="text-center p-5">

<img src={logo} className="img-fluid" style={{ maxWidth: "300px" }} />

<h6 className="text-muted mt-5">

© Copyright 2024 Sorteos RM. Sitio desarrollado por:    
</h6>
<h6 className="text-muted">

</h6>

<img src={solucionesfelix} className="img-fluid" style={{ maxWidth: "100px" }} />


</div>

</div>

</>)

}


