import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import dayjs from "dayjs";
import iziToast from "izitoast";
import "izitoast/dist/css/iziToast.css";

import {
    Backdrop,
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { getInfoUsuario, gteImagenes, guardar, uploadImagenes } from "../Funciones/Sorteos";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import SelectImagen from "../Componentes/SelectImagen";

export default function DialogoImagenes({ setOpen, id = 0 }) {
  const descriptionElementRef = useRef(null);
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [fecha, setFecha] = useState(dayjs());

  const [Values, setValues] = useState({
    id: id ?? 0,
    nombre: "",
    descripcion_sorteo: "",
    fecha_fin: dayjs(),
    precio: 0,
    folio_inicial: 1,
    max_boletos: 1000,
  });
  const [Errores, setErrores] = useState({
    nombre: false,
    descripcion_sorteo: false,
    fecha_fin: false,
    precio: false,
    folio_inicial: false,
    max_boletos: false,
  });
  const handleClose = () => {
    setOpen(false);
  };
  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value ? target.value : "";

    if (Name == "precio" || Name == "folio_inicial" || Name == "max_boletos") {
      const regex = /^[0-9]*$/; // Expresión regular para aceptar solo números enteros

      if (regex.test(Value)) {
        setValues((prevValues) => ({
          ...prevValues,
          [Name]: Number(Value),
        }));
      }
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        [Name]: Value,
      }));
    }

    setErrores({
      ...Errores,
      [Name]: false,
    });
  };
  // alert(id)
  useEffect(() => {
    setIsLoading(true);
    if (id != 0) {
        gteImagenes(id)
        .then((resp) => {
          if (resp?.err?.length == 0) {
            iziToast.error({
              title: "ERROR",
              message: "Ha ocurrido un error, intenta nuevamente ",
              position: "topCenter",
            });
            setOpen(false);
          } else {
            setValues(resp);
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          iziToast.error({
            title: "ERROR",
            message: "Ha ocurrido un error, intenta nuevamente ",
            position: "topCenter",
          });
          setOpen(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const uploadImagen = (img, tipo, extencion) => {
    setIsGuardando(true);
    const currentDate = dayjs();
    const formattedDate = currentDate.format("YYYY-MM-DD");
    Values.fecha_fin = formattedDate; 
    uploadImagenes(img, tipo, id, extencion)
      .then((data) => {
        if (data.estatus == "OK") {
          iziToast.success({
            title: "OK",
            message: data.mensaje,
            position: "topCenter",
          });
          setIsGuardando(false);
         // setOpen(false);
        } else {
          setIsGuardando(false);
          iziToast.error({
            title: "ERROR",
            message: data.mensaje,
            position: "topCenter",
          });
        }
      })
      .catch((data) => {
        console.log(data);
        iziToast.error({
          title: "ERROR",
          message: data.mensaje,
          position: "topCenter",
        });
        setIsGuardando(false);
      });
  };

  React.useEffect(() => {
    const { current: descriptionElement } = descriptionElementRef;
    if (descriptionElement !== null) {
      descriptionElement.focus();
    }
  }, []);

  return (
    <div>


{
        IsGuardando &&
        <Backdrop
        open={true}
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 999 }}
  
>
  <div className='d-flex justify-content-center align-content-center'>
<CircularProgress color="inherit" />
<div className='ms-3 align-self-center'>
  <h4 className='mb-0 '>Cargando...</h4>
  <h6 className='mb-0 '>Por favor espere </h6>
</div>

  </div>
  
</Backdrop>

      }

      <Dialog
        fullWidth
        maxWidth={"md"}
        open={true}
        onClose={handleClose}
        scroll={"body"}
      >
        <div className="position-absolute" style={{ right: 0 }}>
          <Tooltip title="Cerrar">
            <IconButton onClick={handleClose} aria-label="Cerrar" size="large">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </div>

        <DialogTitle id="scroll-dialog-title">Cargar imagenes</DialogTitle>
        <DialogContent dividers={"paper"}>
          {IsLoading ? (
            <>
              <div className="d-flex justify-content-center p-5 w-100">
                <CircularProgress color="success" />
                <span className="ms-3 align-self-center">Cargando...</span>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <div className="col-12 ">
                  <h6 className="fw-bold">{"Carrusel página inicio"}</h6>
                </div>
                <div className="col-12 ps-3">
                  <SelectImagen
                  imagen={Values.IMG1}
                    titulo="Imagen 1"
                    uploadImagen={uploadImagen}
                    tipo="img1"
                  />
                </div>
                <div className="col-12 mt-3">
                  <SelectImagen
                   imagen={Values.IMG2}
                    titulo="Imagen 2"
                    uploadImagen={uploadImagen}
                    tipo="img2"
                  />
                </div>
                <div className="col-12 mt-3">
                  <SelectImagen
                   imagen={Values.IMG3}
                    titulo="Imagen 3"
                    uploadImagen={uploadImagen}
                    tipo="img3"
                  />
                </div>

                <div className="col-12 mt-4">
                  <h6 className="fw-bold">{"Banner comprar boleto"}</h6>
                </div>
                <div className="col-12">
                  <SelectImagen
                   imagen={Values.BANNER}
                    titulo="Banner"
                    uploadImagen={uploadImagen}
                    tipo="banner"
                  />
                </div>

                <div className="col-12 mt-4">
                  <h6 className="fw-bold">{"Plantilla Boleto (320 x 640)"}</h6>
                </div>
                <div className="col-12">
                  <SelectImagen
                   imagen={Values.BOLETO}
                    titulo="Plantilla"
                    uploadImagen={uploadImagen}
                    tipo="BOLETO"
                  />
                </div>
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="error" variant="outlined" onClick={handleClose}>
            Salir
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
