import "./App.css";
import { Chip, Paper, Stack } from "@mui/material";
import logo from "./Assets/Logo.png";
import { Route, Routes } from "react-router-dom";
import P404 from "./404";
import Header from "./Includes/Header";
import Carrusel from "./Includes/Carrusel";
import Inicio from "./Inicio";
import { AppProvider , AppContext } from './Contextos/AppContext';
import { useContext } from "react";
import DataSorteo from "./Contextos/DataSorteo";
import RotesWeb from "./RotesWeb";
import Footer from "./Includes/Footer";
import WhatsAppButton from "./Includes/WhatsAppButton";
function App() {


 
  return (
    <>
     <AppProvider>
<DataSorteo /> 
      <Header />
      <RotesWeb />
    
      <WhatsAppButton />
      </AppProvider>
     
    </>
  );
}

export default App;
