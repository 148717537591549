import { Card } from "@mui/material";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import imagenDefault  from "../../../Assets/imagenDefault.png";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const SelectImagen = ({titulo="Imagen", imagen="", tipo ="",uploadImagen}) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [img, setImge] = useState(imagen==""?imagenDefault:imagen);


  const onDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    setSelectedFile(file);

    const base64 = await convertToBase64(file);
    const fileExtension = getFileExtension(file.name);
    setImge(base64)
    uploadImagen(base64, tipo,fileExtension )
  };
 
  const getFileExtension = (fileName) => {
    return fileName.split('.').pop();
  };
  const clearImage = (event) => {
    event.preventDefault();
    setSelectedFile(null);
    setImge(imagenDefault);
  };

  const { fileRejections, getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop: onDrop,
    accept: {
      'image/webp': [],
      'image/jpg': [],
      'image/jpeg': [],
      'image/png': []
    }
  });

 
  const convertToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  return (
    <Card elevation={isDragActive?5:0} className="p-3 border-line">
          <div className="d-flex justify-content-start">
      <div
        {...getRootProps()}
        className={`w-100 dropzone ${isDragActive ? "active" : ""}`}
      > 
        <div className="d-flex justify-content-start w-100"> 
          <div className="" style={{ width: "150px" }}>
            <img className="w-100" src={img}   />
          </div>

          <div className="ps-4 align-self-center">
          <h6 className="fw-bold">{titulo}</h6>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Arrastra tu archicvo aquí</p>
            ) : (
              <p>Arrastra o <span className="text-success fw-bold">selecciona</span> tu arrastra aquí tu archivo</p>
            )} 
          </div> 
        </div>
        </div>
        <div className="ps-4 align-self-center">
          <IconButton  type="button" onClick={clearImage} aria-label="delete" size="large">
        <DeleteIcon color="error" />
      </IconButton>
            </div>
      </div>
    </Card>
  );
};

export default SelectImagen;
