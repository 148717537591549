 
import { getData, postdData, postUrl } from "../../Contextos/backend";
import validator from "validator";
 
import { stringify } from "query-string";
import { ConvertDate } from "../../../Includes/Generales";

///////////////////
/* FUNCIONES GET */
///////////////////
export const getLista = async (fechaIn, fechaFin,estatus,tipoFiltro,ValueBusqueda) => {
 
  let data = [];
  let queryParamsObj = { 
    fechaIn:ConvertDate(fechaIn),
    fechaFin:ConvertDate(fechaFin),
    estatus:estatus,
    tipoFiltro:tipoFiltro,
    query :ValueBusqueda
  };  
  const url = `ventas/Listar?${stringify(queryParamsObj)}`
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) { 
      data = res.data;
      resolve(data);
    } else {
      reject(res);
    }
  });
};
 

export const getInfoVenta = async (id) => {
  let data = []; 
  const url = `ventas/Consultar?id=`+id;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) { 
      data = JSON.parse(res.data.objRespuesta); 
      console.log(data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de este cliente",
        });
      } else {
        data = data[0];
        console.log(data);
        let Values = {
          id: String(data.ID),
          nombre: String(data.NOMBRE), 
          correo: String(data.CORREO),
          telefono: String(data.TELEFONO) 
        }; 
        resolve(Values);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

////////////////////
/* FUNCIONES POST */
////////////////////
export const guardar = async (Values, id, fecha) => {
  let error = false;

  /* REQUERIDOS */
  if (!Values.comentarios || Values.comentarios == "") {
  Values.comentarios =""; 
  } 

  if (!error) {
     
    let data = [];
    const body = 
    {
      id:id,
      comentarios:Values.comentarios,
      fecha:fecha
    };
    console.log("Datos", body);
    const url = "ventas/update";

    const res = await postdData(url, body);
    console.log(res);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data; 
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};



export const cancelar = async (Values, id, fecha) => {
  let error = false; 
  if (!Values.comentarios || Values.comentarios == "") {
  Values.comentarios =""; 
  }  
  if (!error) {
     
    let data = [];
    const body = 
    {
      id:id,
      comentarios:Values.comentarios,
      fecha:fecha
    };
    console.log("Datos", body);
    const url = "ventas/cancelar";

    const res = await postdData(url, body);
    console.log(res);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data; 
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};





export const Eliminar = async (id) => {
  if (id != undefined) {
    let data = []; 
    const url = `usuarios/Eliminar?id=`+id;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
}; 