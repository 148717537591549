import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { clearStorageJWT } from "../../Contextos/Storage";
 

export default function Confirmar({
  setOpen= ()=>{},
  funcionEjecutar = ()=>{},
  Titulo,
  TextoAyuda = "",
  TextCancelar = "Cancelar",
  TextoOK = "Si, seguro",
  openLoading
}) {
  return (
<> 
    <Dialog
      open={true} 
      aria-labelledby="responsive-dialog-title"
    >
 
      <DialogTitle id="responsive-dialog-title">
       {Titulo}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
        {TextoAyuda}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => {
            setOpen(false);
          }}
        >
          {TextCancelar}
        </Button>
        <Button onClick={funcionEjecutar} autoFocus>
        {TextoOK}
        </Button>
      </DialogActions>
    </Dialog>

    {openLoading && (
      <Backdrop
        open={true}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 9999 }}
      >
        <div className="d-flex justify-content-center align-content-center">
          <CircularProgress color="inherit" />
          <div className="ms-3 align-self-center">
            <h4 className="mb-0 ">Espere...</h4>
          </div>
        </div>
      </Backdrop>
    )}
    </>
  );
}
