import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { getInfo, guardar } from "../Funciones/Configuraciones";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import iziToast from "izitoast";
import "izitoast/dist/css/iziToast.css";
import Select from "../../Includes/Select";

export default function DialogoForma({ setOpen, id = 0, getListaAdmin }) {
  const descriptionElementRef = useRef(null);
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [Values, setValues] = useState({
    id: id ?? 0,
    banco: "",
    clave: "",
    titular: "",
    tarjeta: "",
    tipo: "",
  });
  const [Errores, setErrores] = useState({
    banco: false,
    clave: false,
    titular: false,
    tarjeta: false,
    tipo: false,
  });
  const handleClose = () => {
    setOpen(false);
  };
  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value ? target.value : "";
    setValues((prevValues) => ({
      ...prevValues,
      [Name]: Value,
    }));
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };
  useEffect(() => {
    setIsLoading(true);
    if (id != 0) {
      getInfo(id)
        .then((resp) => {
          if (resp?.err?.length == 0) {
            iziToast.error({
              title: "ERROR",
              message: "Ha ocurrido un error, intenta nuevamente ",
              position: "topCenter",
            });
            setOpen(false);
          } else {
            setValues(resp);
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          iziToast.error({
            title: "ERROR",
            message: "Ha ocurrido un error, intenta nuevamente ",
            position: "topCenter",
          });
          setOpen(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const GuardarUsusario = () => {
    setIsGuardando(true);
    guardar(Values, Errores, setErrores)
      .then((data) => {
        if (data.estatus == "OK") {
          iziToast.success({
            title: "OK",
            message: data.mensaje,
            position: "topCenter",
          });
          getListaAdmin();
          setIsGuardando(false);
          setOpen(false);
        } else {
          iziToast.error({
            title: "ERROR",
            message: data.mensaje,
            position: "topCenter",
          });
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        iziToast.error({
          title: "ERROR",
          message: data.mensaje,
          position: "topCenter",
        });

        alert(data.mensaje);
        setIsGuardando(false);
      });
  };

  React.useEffect(() => {
    const { current: descriptionElement } = descriptionElementRef;
    if (descriptionElement !== null) {
      descriptionElement.focus();
    }
  }, []);

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={true}
        onClose={handleClose}
        scroll={"body"}
      >
        <div className="position-absolute" style={{ right: 0 }}>
          <Tooltip title="Cerrar">
            <IconButton onClick={handleClose} aria-label="Cerrar" size="large">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </div>

        <DialogTitle id="scroll-dialog-title">
          {id == 0 ? "Nuevo forma de pago" : "Editar forma de pago"}
        </DialogTitle>
        <DialogContent dividers={"paper"}>
          {IsLoading ? (
            <>
              <div className="d-flex justify-content-center p-5 w-100">
                <CircularProgress color="success" />
                <span className="ms-3 align-self-center">Cargando...</span>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <div className="col-12 col-md-6 pb-3">
                  <Select
                    titulo="Tipo"
                    size="medium"
                    Name="tipo"
                    ValueSelect={Values.tipo}
                    handlInputChange={handlInputChange}
                    opciones={[
                      { value: "Transferencias", label: "Trasferencias" },
                      { value: "Depósitos", label: "Depósitos" },
                      { value: "Extranjero", label: "Extranjero" },
                    ]}
                  />
                </div>
                <div className="col-12 col-md-6 pb-3">
                  <Select
                    titulo="Banco"
                    size="medium"
                    Name="banco"
                    label="Banco"
                    ValueSelect={Values.banco}
                    handlInputChange={handlInputChange}
                    opciones={[
                      { value: "Bancomer", label: "Bancomer" },
                      { value: "Santander", label: "Santander" },
                      { value: "Banamex", label: "Banamex" },
                      { value: "Spin Oxxo", label: "Spin Oxxo" },
                    ]}
                  />
                </div>

                <div className="col-12   pb-3">
                  <TextField
                    fullWidth
                    name="titular"
                    label="Titular"
                    variant="outlined"
                    color="success"
                    value={Values.titular}
                    error={Errores.titular}
                    helperText={Errores.titular ? "Titular no valido" : ""}
                    onChange={handlInputChange}
                  />
                </div>
                <div className="col-12 col-md-6 pb-3">
                  <TextField
                    fullWidth
                    name="clave"
                    label="Clave"
                    variant="outlined"
                    color="success"
                    value={Values.clave}
                    error={Errores.clave}
                    helperText={Errores.clave ? "Clave no valido" : ""}
                    onChange={handlInputChange}
                  />
                </div>
                <div className="col-12 col-md-6 pb-3">
                  <TextField
                    fullWidth
                    name="tarjeta"
                    label="Número de tarjeta"
                    variant="outlined"
                    color="success"
                    value={Values.tarjeta}
                    error={Errores.tarjeta}
                    helperText={
                      Errores.tarjeta ? "Número de tarjeta no valido" : ""
                    }
                    onChange={handlInputChange}
                  />
                </div>
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <LoadingButton
            loading={IsGuardando}
            loadingPosition="start"
            disabled={IsLoading}
            onClick={() => GuardarUsusario()}
            variant="contained"
            color="success"
            xs={{ with: "100$" }}
          >
            <span className={IsGuardando ? "px-4" : "px-2"}>
              {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
            </span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
