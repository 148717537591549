import { stringify } from "query-string";
import { getData, postdData, postUrl } from "../../Contextos/backend";
import validator from "validator";
 
///////////////////
/* FUNCIONES GET */
///////////////////
export const getLista = async () => {
 
  let data = [];
  let queryParamsObj = { 
  };  
  const url = `formas/Listar`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) { 
      data = res.data;
      resolve(data);
    } else {
      reject(res);
    }
  });
};
 

export const getInfo = async (id) => {
  let data = []; 
  const url = `formas/Consultar?id=`+id;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) { 
      data = JSON.parse(res.data.objRespuesta); 
      console.log(data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de este cliente",
        });
      } else {
        data = data[0];
        console.log(data);
        let Values = {
          id: String(data.ID),
          banco: String(data.BANCO),
          clave: String(data.CLAVE_INTER),
          titular: String(data.TITULAR),
          tarjeta: String(data.TARJETA),
          tipo: String(data.TIPO),
        }; 
        resolve(Values);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

////////////////////
/* FUNCIONES POST */
////////////////////
export const guardar = async (Values, Errores, setErrores) => {
  let error = false;


   

  /* REQUERIDOS */
  if (!Values.banco || Values.banco == "") {
    let Errores2 = Errores; 
    Errores2.banco = true;
    setErrores(Errores2);
    error = true;
  }
  
    if (!Values.tipo || Values.tipo == "") {
    let Errores2 = Errores; 
    Errores2.tipo = true;
    setErrores(Errores2);
    error = true;
  }
  
   

  if (!error) {
     
    let data = [];
    const body = Values;
    console.log("Datos", body);
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "formas/Insertar"
        : "formas/Editar";

    const res = await postdData(url, body);
    console.log(res);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data; 
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const Eliminar = async (id) => {
  if (id != undefined) {
    let data = []; 
    const url = `formas/Eliminar?id=`+id;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
}; 



////////   CONFIG    ///////////



export const getInfoConfigs = async () => {
  let data = []; 
  const url = `config/listar`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) { 
      data = JSON.parse(res.data.objRespuesta); 
      console.log(data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de este cliente",
        });
      } else {
        data = data[0];
        console.log(data);
        let Values = { 
          WHATSAPP1: String(data.WHATSAPP1),
          WHATSAPP2: String(data.WHATSAPP2),
          WHATSAPP3: String(data.WHATSAPP3),
          WHATSAPP4: String(data.WHATSAPP4), 
        }; 
        resolve(Values);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};
export const gtedash = async () => {
  let data = []; 
  const url = `sorteos/activoDashboard`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) { 
      data = JSON.parse(res.data.objRespuesta); 
      console.log(data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No hay datos",
        });
      } else {
       // data = data[0];
        console.log(data); 
        resolve(data);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

////////////////////
/* FUNCIONES POST */
////////////////////
export const guardarConfig = async (Values, Errores, setErrores) => {
  let error = false;

 


      /* REQUERIDOS */
  if (!Values.WHATSAPP1 || Values.WHATSAPP1 == "") {
    Errores.WHATSAPP1 = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isNumeric(Values.WHATSAPP1)) {
      Errores.WHATSAPP1 = true;
      setErrores(Errores);
      error = true;
    } else if (Values.WHATSAPP1.length != 10) {
      Errores.WHATSAPP1 = true;
      setErrores(Errores);
      error = true;
    }
  } 

 
    if (Values.WHATSAPP2 != "") {
      if (!validator.isNumeric(Values.WHATSAPP2)) {
        Errores.WHATSAPP2 = true;
        setErrores(Errores);
        error = true;
      } else if (Values.WHATSAPP2.length != 10) {
        Errores.WHATSAPP2 = true;
        setErrores(Errores);
        error = true;
      }
    }
  
     if (Values.WHATSAPP3 != "") {
      if (!validator.isNumeric(Values.WHATSAPP3)) {
        Errores.WHATSAPP3 = true;
        setErrores(Errores);
        error = true;
      } else if (Values.WHATSAPP3.length != 10) {
        Errores.WHATSAPP3 = true;
        setErrores(Errores);
        error = true;
      }
    }


    if (Values.WHATSAPP4 != "") {
      if (!validator.isNumeric(Values.WHATSAPP4)) {
        Errores.WHATSAPP4 = true;
        setErrores(Errores);
        error = true;
      } else if (Values.WHATSAPP4.length != 10) {
        Errores.WHATSAPP4 = true;
        setErrores(Errores);
        error = true;
      }
    }

  if (!error) {
     
    let data = [];
    const body = Values;
    console.log("Datos", body);
    const url = "config/Editar"; 
    const res = await postdData(url, body);
    console.log(res);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data; 
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};