import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { clearStorageJWT } from "../../Contextos/Storage";


export default function CerrarSesion   ({setShowDialogoCerrarSesion})
{



    return(<Dialog 
        open={true}
       //  onClose={handleClose} 
        aria-labelledby="responsive-dialog-title"
        >
        <DialogTitle id="responsive-dialog-title">
         ¿Estas seguro de cerrar sesión?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
           Será necesario identificarte con usaurio y contraseña para acceder nuevamete al panel de administrador
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={()=>{setShowDialogoCerrarSesion(false)}}>
            Cancelar
          </Button>
          <Button onClick={clearStorageJWT} autoFocus>
         Cerrar sesión
          </Button>
        </DialogActions>
        </Dialog>);
} 