import { useContext } from "react";
import { AppContext } from "./Contextos/AppContext";
import { formatearFecha } from "./Includes/Generales";
import Contacto from "./Contacto";
import bbva from "./Assets/bbva.jpg";
import spin from "./Assets/spin.jpg";
import santander from "./Assets/santander.png";
import banamex from "./Assets/banamex.jpeg";

import PaymentIcon from "@mui/icons-material/Payment";
import { Avatar } from "@mui/material";
import Footer from "./Includes/Footer";
import Aviso from "./Includes/Aviso";

export default function Metodos() {
  const { arrayData } = useContext(AppContext);

  return (
    <>
      <div className="container-lg ">
        <div className="w-100 mt-5 mb-5">
          <h1 className="fw-bold text-center mb-5">Métodos de pago</h1>
          <div className="w-100 d-flex justify-content-center flex-wrap">
            {arrayData.formas.map((row) => {
              return (
                <>
                  <div
                    className="card shadow-lg border-0 p-0 mb-4 mx-auto me-2 ms-2"
                    style={{ maxWidth: "331px" }}
                  >
                    <div className="d-flex flex-column justify-content-star align-items-center  ">
                      <div className=" align-self my-4">
                        {row.BANCO == "Bancomer" ? ( 
                            <img src={bbva} style={{maxHeight:"80px", borderRadius:"21px"}} className="img-fluid" />
                        
                        ) : row.BANCO == "Spin Oxxo" ? (
                           
                            <img src={spin} style={{maxHeight:"80px", borderRadius:"21px"}} className="img-fluid" />
                         
                        ) : 
                        row.BANCO == "Banamex" ? ( 
                            <img src={banamex} style={{maxHeight:"80px"}} className="img-fluid" />
                       
                        ) :  
                        row.BANCO == "Santander" ? (
                         
                            <img src={santander} width={"170px"} style={{maxHeight:"80px", borderRadius:"21px"}} className="img-fluid" />
                        
                        ) :  
                        
                        (
                          <Avatar
                            style={{ backgroundColor: "#7C8240" }}
                            className="p-4 shadow-lg"
                          >
                            <PaymentIcon
                              style={{ fontSize: "2rem" }}
                              color="white"
                            />
                          </Avatar>
                        )}
                      </div>
                      <div className="flex-grow-1  p-4 pt-1 ">
                        <h4 className="fw-bold text-star mb-2 text-center mb-3">
                          {row.TIPO}
                        </h4>
                        <h6 className="s text-star mb-0 text-muted">BANCO</h6>
                        <h6 className="fw-bold text-star mb-2">{row.BANCO}</h6>

                        {row.CLAVE_INTER != "" && (
                          <>
                            <h6 className="s text-star mb-0 text-muted ">
                              CLAVE INTERBANCARIA
                            </h6>
                            <h6 className="fw-bold text-star mb-2">
                              {row.CLAVE_INTER}
                            </h6>
                          </>
                        )}

                        {row.TARJETA != "" && (
                          <>
                            <h6 className="s text-star mb-0 text-muted">
                              NÚM TARJETA
                            </h6>
                            <h6 className="fw-bold text-star mb-2">
                              {row.TARJETA}
                            </h6>
                          </>
                        )}

                        {row.TITULAR != "" && (
                          <>
                            <h6 className="s text-star mb-0 text-muted">
                              TITULAR
                            </h6>
                            <h6 className="fw-bold text-star mb-2">
                              {row.TITULAR}
                            </h6>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>

<Aviso />
        
        </div>
      </div>

      <Contacto />
      <Footer />s
    </>
  );
}
