import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
    Card,
  CircularProgress,
  IconButton, 
  Paper, 
  TextField,
  Tooltip,
} from "@mui/material";
import { getInfo, getInfoConfigs, guardar, guardarConfig } from "../Funciones/Configuraciones";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import iziToast from "izitoast";
import "izitoast/dist/css/iziToast.css";
import Select from "../../Includes/Select";

export default function Generales( ) {
  const descriptionElementRef = useRef(null);
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [Values, setValues] = useState({ 
    WHATSAPP1: "",
    WHATSAPP2: "",
    WHATSAPP3: "",
    WHATSAPP4: "", 
  });
  const [Errores, setErrores] = useState({
    WHATSAPP1: false, 
    WHATSAPP2: false, 
    WHATSAPP3: false, 
    WHATSAPP4: false, 
  });
 
  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value ? target.value : "";
    setValues((prevValues) => ({
      ...prevValues,
      [Name]: Value,
    }));
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };
  useEffect(() => {
    setIsLoading(true); 
    getInfoConfigs()
        .then((resp) => {
          if (resp?.err?.length == 0) {
            iziToast.error({
              title: 'ERROR',
              message: "Ha ocurrido un error, intenta nuevamente ",
              position: 'topCenter',
            }); 
          } else {
            setValues(resp);
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          iziToast.error({
            title: 'ERROR',
            message: "Ha ocurrido un error, intenta nuevamente ",
            position: 'topCenter',
          }); 


        });
    
  }, []);

  const GuardarUsusario = () => {
    setIsGuardando(true);
    guardarConfig(Values, Errores, setErrores)
      .then((data) => {
        if (data.estatus == "OK") {
          iziToast.success({
            title: "OK",
            message: data.mensaje,
            position: "topCenter",
          });
          //getListaAdmin();
          setIsGuardando(false);
          //setOpen(false);
        } else {
          iziToast.error({
            title: "ERROR",
            message: data.mensaje,
            position: "topCenter",
          });
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        iziToast.error({
          title: "ERROR",
          message: data.mensaje,
          position: "topCenter",
        });

        alert(data.mensaje);
        setIsGuardando(false);
      });
  };

  React.useEffect(() => {
    const { current: descriptionElement } = descriptionElementRef;
    if (descriptionElement !== null) {
      descriptionElement.focus();
    }
  }, []);

  return (   <Paper className="shadow border mb-5 p-3">

  <div>   
        <h6 className="fw-bold mb-4"> Configuraciones de teléfonos</h6>
  
          {IsLoading ? (
            <>
              <div className="d-flex justify-content-center p-5 w-100">
                <CircularProgress color="success" />
                <span className="ms-3 align-self-center">Cargando...</span>
              </div>
            </>
          ) : (
            <>
              <div className="row"> 
                <div className="col-12 col-md-6  pb-3">
                  <TextField
                    fullWidth
                    name="WHATSAPP1"
                    label="WhatsApp principal"
                    variant="outlined"
                    color="success"
                    value={Values.WHATSAPP1}
                    error={Errores.WHATSAPP1}
                    helperText={"Número WhatsApp principal, este se muestra en el botón flotante"} 
                    onChange={handlInputChange}
                  />
                </div>
                <div className="col-12 col-md-6  pb-3">
                  <TextField
                    fullWidth
                    name="WHATSAPP2"
                    label="WhatsApp"
                    variant="outlined"
                    color="success"
                    value={Values.WHATSAPP2}
                    error={Errores.WHATSAPP2}
                    helperText={"Número WhatsApp secundario"} 
                    onChange={handlInputChange}
                  />
                </div>
                <div className="col-12 col-md-6  pb-3">
                  <TextField
                    fullWidth
                    name="WHATSAPP3"
                    label="WhatsApp"
                    variant="outlined"
                    color="success"
                    value={Values.WHATSAPP3}
                    error={Errores.WHATSAPP3}
                    helperText={"Número WhatsApp secundario"} 
                    onChange={handlInputChange}
                  />
                </div>

                <div className="col-12 col-md-6  pb-3">
                  <TextField
                    fullWidth
                    name="WHATSAPP4"
                    label="WhatsApp"
                    variant="outlined"
                    color="success"
                    value={Values.WHATSAPP4}
                    error={Errores.WHATSAPP4}
                    helperText={"Número WhatsApp secundario"} 
                    onChange={handlInputChange}
                  />
                </div>
             
              </div>
            </>
          )}
    
    <div className="col-23 text-end">
   <LoadingButton
            loading={IsGuardando}
            loadingPosition="start"
            disabled={IsLoading}
            onClick={() => GuardarUsusario()}
            variant="contained"
            color="success"
            xs={{ with: "100$" }}
          >
            <span className={IsGuardando ? "px-4" : "px-2"}>
              {IsGuardando ? "Guardando..." :   "Guardar configuraciones" }
            </span>
            </LoadingButton>
           
    </div>
         
       
  
    </div>
    </Paper>
  );
}
