
import React, { useState, useEffect } from "react";
import Select from "../../Includes/Select";
import dayjs from "dayjs";
import { Button, FormControl, InputLabel, OutlinedInput } from "@mui/material";
import { DatePickerInput } from "../../Includes/DatePicker";
import BACKEND_URL from "../../Contextos/backend";
import { ConvertDate } from "../../../Includes/Generales";
import { stringify } from "query-string";
import DownloadIcon from '@mui/icons-material/Download';

export default function ReporteVentas() { 
    const [fechaIn, setFechaIn] = useState(dayjs().subtract(7, "day"));
    const [fechaFin, setFechafin] = useState(dayjs().subtract(-1, "day")); 
    const [estatus, setEstatus] = useState("Todos");

    const handlInputChange = ({ target }) => {
        let Name = target.name;
        let Value = target.value ? target.value : "";
        if(Name=="estatus")
        {
          setEstatus(Value);
        } 
      };


      const goRep=()=>
      {

        let queryParamsObj = { 
            fechaIn:ConvertDate(fechaIn),
            fechaFin:ConvertDate(fechaFin),
            estatus:estatus,
            tipoFiltro:"Todos",
            query :""
          };  
          const url =    BACKEND_URL +`ventas/ReporteExcel?${stringify(queryParamsObj)}`;
          window.open(url, '_blank');
      } 
    return (
      <div className="p-1 p-md-4">  
      
      <h4 className="fw-bold">
        Reporte de ventas
      </h4>
           <div className="d-flex justify-content-star flex-wrap  w-100 mb-3">
        <div className="mt-3 me-0 me-sm-2" style={{ width: "170px" }}>
          <DatePickerInput
            size="small"
            name="fecha_fin"
            label="Fecha inicio"
            fecha={fechaIn}
            setFecha={setFechaIn}
          />
        </div>
        

        <div className="mt-3  me-0 me-sm-2" style={{ width: "170px" }}>
          <DatePickerInput
            size="small"
            name="fecha_fin"
            label="Fecha fin"
            fecha={fechaFin}
            setFecha={setFechafin}
          />
        </div>

        <div className="mt-3  me-0 me-sm-2" style={{ width: "170px" }}>
          <Select
            titulo="Estatus"
            size="small"
            Name="estatus"
            label="Estatus"
            ValueSelect={estatus}
            handlInputChange={handlInputChange}
            opciones={[
              { value: "Pagado", label: "Pagado" },
              { value: "Cancelado", label: "Cancelado" },
              { value: "Apartados", label: "Apartados" },
              { value: "Todos", label: "Todos" },
            ]}
          />
        </div> 
     
      </div>

      <div>
            <div className="w-100 d-flex justify-content-end">
 
            <Button
          onClick={() => {
            goRep();
          }}
          color="success"
          className="mb-0"
          variant="contained" 
          endIcon={<DownloadIcon />}
        >
         Descargar reporte excel
        </Button>
         
            </div>
          
        </div>
      </div>
    );
  }
  