 

 export function formatearFecha(fechaString) {
  const meses = [
    "enero", "febrero", "marzo", "abril", "mayo", "junio",
    "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
  ];

  const fecha = new Date(`${fechaString}T00:00:00Z`);
  const dia = fecha.getUTCDate();
  const mes = fecha.getUTCMonth();
  const anio = fecha.getUTCFullYear();

  const fechaLegible = `${dia} de ${meses[mes]} del ${anio}`;
  return fechaLegible;
  }
  
export function formatMoneda(number) {
  const precio = number;
  const precioFormateado = precio.toLocaleString("es-MX", {
    style: "currency",
    currency: "MXN",
  });
  return precioFormateado;
}

export function formatNumber(number) {
  const precio = number;
  const precioFormateado = precio.toLocaleString("es-MX", {
    style: "currency",
    currency: "MXN",
  });
  return precioFormateado;
}




export function ConvertDate(fecha) {
  if (typeof fecha == "object" && fecha) {
    let fechaFormateada = "";
    let yyyy = fecha["$y"];
    let mm = fecha["$M"] + 1;
    let dd = fecha["$D"];
    fechaFormateada =
      yyyy + "-" + (mm < 10 ? "0" + mm : mm) + "-" + (dd < 10 ? "0" + dd : dd);
    return fechaFormateada;
  } else {
    return fecha;
  }
}