import * as React from 'react';
import Box from '@mui/material/Box';
import { withStyles } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';

export default function Loading() {
 
  return ( 
    <Box className='my-2' sx={{ width: '100%' }}>
      <LinearProgress color='success'  />
    </Box>
  );
}