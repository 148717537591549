import * as React from "react";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import RecentActorsOutlinedIcon from "@mui/icons-material/RecentActorsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import CottageOutlinedIcon from "@mui/icons-material/CottageOutlined";
import { Link, useLocation } from "react-router-dom";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import PieChartOutlineRoundedIcon from "@mui/icons-material/PieChartOutlineRounded";
import { Avatar, AvatarGroup, Button, Divider, Drawer } from "@mui/material";
import { getLocalStorage } from "../Contextos/Storage";
import LoginRoundedIcon from "@mui/icons-material/LoginRounded";
import CerrarSesion from "./Dialogos/CerrarSesion";
import SettingsIcon from '@mui/icons-material/Settings';

export default function Sidebar({
  sidebarMini,
  setSidebarMini,
  openMenuMovil,
  setPpenMenuMovil,
}) {
  const location = useLocation();
  const [showDialogoCerrarSesion, setShowDialogoCerrarSesion] =
    React.useState(false);

  return (
    <>
      {showDialogoCerrarSesion && (
        <CerrarSesion setShowDialogoCerrarSesion={setShowDialogoCerrarSesion} />
      )}
      <div>
       
          <Drawer
            open={openMenuMovil}
            className="d-block d-md-none"
            onClose={() => {
              setPpenMenuMovil(false);
            }}
            anchor="bottom"
          >
            <Menu
              setPpenMenuMovil={setPpenMenuMovil}
              setShowDialogoCerrarSesion={setShowDialogoCerrarSesion}
            />
          </Drawer>
         
      </div>

      <List
        sx={{
          width: "100%",
          maxWidth: 700,
          bgcolor: "background.paper",
          position: "sticky",
        }}
        className="pt-3"
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader
            component="div"
            id="nested-list-subheader"
          ></ListSubheader>
        }
      >
        {!sidebarMini ? (
          <>
            <Menu
              setPpenMenuMovil={setPpenMenuMovil}
              setShowDialogoCerrarSesion={setShowDialogoCerrarSesion}
            />
          </>
        ) : (
          <div className="d-flex flex-column  ">
            <Link to="./" style={{ textDecoration: "none", color: "black" }}>
              <Button
                variant="text"
                className={
                  "text-muted " +
                  (location.pathname == "/admin/" ? "menu-active" : "")
                }
              >
                <div className="d-flex flex-column align-content-center">
                  <div>
                    <CottageOutlinedIcon />
                  </div>
                  <div>
                    <span style={{ fontSize: "9px", textTransform: "none" }}>
                      Dashboard
                    </span>
                  </div>
                </div>
              </Button>
            </Link>
            <Link
              to="./sorteos"
              style={{ textDecoration: "none", color: "black" }}
            >
              <Button
                className={
                  "text-muted " +
                  (location.pathname.includes("/sorteos") ? "menu-active" : "")
                }
                variant="text"
              >
                <div className="d-flex flex-column align-content-center">
                  <div>
                    <FormatListBulletedIcon />
                  </div>
                  <div>
                    <span style={{ fontSize: "9px", textTransform: "none" }}>
                      Sorteos
                    </span>
                  </div>
                </div>
              </Button>
            </Link>

            <Link
              to="./ventas"
              style={{ textDecoration: "none", color: "black" }}
            >
              <Button
                className={
                  "text-muted " +
                  (location.pathname.includes("/ventas") ? "menu-active" : "")
                }
                variant="text"
              >
                <div className="d-flex flex-column align-content-center">
                  <div>
                    <AttachMoneyIcon />
                  </div>
                  <div>
                    <span style={{ fontSize: "9px", textTransform: "none" }}>
                      {" "}
                      Ventas
                    </span>
                  </div>
                </div>
              </Button>
            </Link>

            <Link
              to="./reportes"
              style={{ textDecoration: "none", color: "black" }}
            >
              <Button
                className={
                  "text-muted " +
                  (location.pathname.includes("/reportes") ? "menu-active" : "")
                }
                variant="text"
              >
                <div className="d-flex flex-column align-content-center">
                  <div>
                    <PieChartOutlineRoundedIcon />
                  </div>
                  <div>
                    <span style={{ fontSize: "9px", textTransform: "none" }}>
                      {" "}
                      Reportes
                    </span>
                  </div>
                </div>
              </Button>
            </Link>
            <Link
              to="./usuarios"
              style={{ textDecoration: "none", color: "black" }}
            >
              <Button  className={
                  "text-muted " +
                  (location.pathname.includes("/usuarios") ? "menu-active" : "")
                } variant="text">
                <div className="d-flex flex-column align-content-center">
                  <div>
                    <PeopleOutlineIcon />
                  </div>
                  <div>
                    <span style={{ fontSize: "9px", textTransform: "none" }}>
                      {" "}
                      Usuarios
                    </span>
                  </div>
                </div>
              </Button>
            </Link>
            <Link
              to="./configuraciones"
              style={{ textDecoration: "none", color: "black" }}
            >
              <Button  className={
                  "text-muted " +
                  (location.pathname.includes("/configuraciones") ? "menu-active" : "")
                } variant="text">
                <div className="d-flex flex-column align-content-center">
                  <div>
                    <SettingsIcon />
                  </div>
                  <div>
                    <span style={{ fontSize: "9px", textTransform: "none" }}>
                      {" "}
                      Config.                  </span>
                  </div>
                </div>
              </Button>
            </Link>
          </div>
        )}
      </List>
    </>
  );
}

const Menu = ({ setPpenMenuMovil, setShowDialogoCerrarSesion }) => {
  const location = useLocation();
  return (
    <>
      <ListItemButton className="d-flex d-md-none">
        <ListItemIcon>
          <Avatar
            alt={getLocalStorage("NOMBRE")}
            sx={{ width: 30, height: 30 }}
          >
            {getLocalStorage("NOMBRE").substring(0, 1)}
          </Avatar>
        </ListItemIcon>
        <ListItemText
          primary={getLocalStorage("NOMBRE")}
          secondary={getLocalStorage("CORREO")}
        />
      </ListItemButton>
      <Divider
        className="d-flex d-md-none"
        style={{ backgroundColor: "grey" }}
      ></Divider>

      <Link to="./" style={{ textDecoration: "none", color: "black" }}>
        <ListItemButton
          onClick={() => {
            setPpenMenuMovil(false);
          }}
          className={location.pathname == "/admin/" && "menu-active"}
        >
          <ListItemIcon>
            <CottageOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton>
      </Link>
      <Link to="./sorteos" style={{ textDecoration: "none", color: "black" }}>
        <ListItemButton
          onClick={() => {
            setPpenMenuMovil(false);
          }}
          className={location.pathname.includes("/sorteos") && "menu-active"}
        >
          <ListItemIcon>
            <FormatListBulletedIcon />
          </ListItemIcon>
          <ListItemText primary="Sorteos" />
        </ListItemButton>
      </Link>

      <Link to="./ventas" style={{ textDecoration: "none", color: "black" }}>
        <ListItemButton
          onClick={() => {
            setPpenMenuMovil(false);
          }}
          className={location.pathname.includes("/ventas") && "menu-active"}
        >
          <ListItemIcon>
            <AttachMoneyIcon />
          </ListItemIcon>
          <ListItemText primary="Ventas" />
        </ListItemButton>
      </Link>

      <Link to="./reportes" style={{ textDecoration: "none", color: "black" }}>
        <ListItemButton
          onClick={() => {
            setPpenMenuMovil(false);
          }}
          className={location.pathname.includes("/reportes") && "menu-active"}
        >
          <ListItemIcon>
            <PieChartOutlineRoundedIcon />
          </ListItemIcon>
          <ListItemText primary="Reportes" />
        </ListItemButton>
      </Link>
      <Link to="./usuarios" style={{ textDecoration: "none", color: "black" }}>
        <ListItemButton
          onClick={() => {
            setPpenMenuMovil(false);
          }}
          className={location.pathname.includes("/usuarios") && "menu-active"}
        >
          <ListItemIcon>
            <PeopleOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="Usuarios" />
        </ListItemButton>
      </Link>
      <Link to="./configuraciones" style={{ textDecoration: "none", color: "black" }}>
        <ListItemButton
          onClick={() => {
            setPpenMenuMovil(false);
          }}
          className={location.pathname.includes("/configuraciones") && "menu-active"}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Configuraciones" />
        </ListItemButton>
      </Link>

      <ListItemButton
        className="d-flex d-md-none"
        onClick={() => {
          setShowDialogoCerrarSesion(true);
          setPpenMenuMovil(false);
        }}
      >
        <ListItemIcon>
          <LoginRoundedIcon color="error" />
        </ListItemIcon>
        <ListItemText primary={"Cerrar sesión"} />
      </ListItemButton>
    </>
  );
};
