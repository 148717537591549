import { Route, Routes } from "react-router-dom";

import Header from "./Includes/Header";
import Dasboard from "./Dashboard/Dasboard";
import Sidebar from "./Includes/Sidebar";

import { TituloProvider } from "./Contextos/TituloContext";

import { useState } from "react";
import { useEffect } from "react";
import { getLocalStorageJWT } from "./Contextos/Storage";

import StartLoad from "./Includes/StartLoad";
import FormLogin from "./LogIn/Componentes/FormLogin";
import P404 from "../404";
import SorteosIndex from "./Sorteos/SorteosIndex";
import UsuariosIndex from "./Usuarios/UsuariosIndex";
import ConfigsIndex from "./Configs/ConfigsIndex";
import VentasIndex from "./Ventas/VentasIndex";
import ReportesIndex from "./Reportes/SorteosIndex";

export default function Index() {
  const [sidebarMini, setSidebarMini] = useState(false);
  const [openMenuMovil, setPpenMenuMovil] = useState(false); 
  const [isLoged, setIsLoged] = useState(false);
  const [load, setLoad] = useState(true);
  const getInfoUsuario = async () => {
    if (getLocalStorageJWT() != "") {
      setIsLoged(true);
    }
  };

  useEffect(() => {
    setLoad(true);
    getInfoUsuario().then(
      setTimeout(() => {
        setLoad(false);
      }, 1500)
    );
  }, []);

  return load ? (
    <StartLoad />
  ) : isLoged ? (
    <>
      <TituloProvider>
        <Header sidebarMini={sidebarMini} setSidebarMini={setSidebarMini} openMenuMovil={openMenuMovil} setPpenMenuMovil={setPpenMenuMovil}/>
        <div className="d-flex justify-content-start flex-nowrap">
          <div
            className="bg-primary_ flex-grow-0 flex-shrink-0 d-none d-md-block"
            style={{ paddingTop: "65px" }}
          >
            <Sidebar
            openMenuMovil={openMenuMovil} setPpenMenuMovil={setPpenMenuMovil}
              sidebarMini={sidebarMini}
              setSidebarMini={setSidebarMini}
            />
          </div>
          <div
            className="bg-secondary_ flex-grow-1 "
            style={{ overflowY: "scroll", height: "100vh" }}
          >
            <div className="p-2 container-main" style={{ marginTop: "25px" }}>
              <div className="container-fluid p-0 p-ms-2">
                <Routes>
                  <Route path="/" element={<Dasboard />}></Route>
                  <Route path="/sorteos" element={<SorteosIndex />}></Route>
                  <Route path="/usuarios" element={<UsuariosIndex />}></Route>
                  <Route path="/configuraciones" element={<ConfigsIndex />}></Route>
                  <Route path="/reportes" element={<ReportesIndex />}></Route>
 <Route path="/ventas" element={<VentasIndex />}></Route>
                  <Route path="/*" element={<P404 />} />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </TituloProvider>
    </>
  ) : (
    <FormLogin />
  );
}
