import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { cancelar, getInfoVenta, guardar } from "../Funciones/Ventas";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import iziToast from "izitoast";
import dayjs from "dayjs";
import "izitoast/dist/css/iziToast.css";
import { DatePickerInput } from "../../Includes/DatePicker";

export default function DialogoUsuario({ setOpen, id = 0, getListaAdmin }) {
  const descriptionElementRef = useRef(null);
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [IsGuardandoCan, setIsGuardandoCan] = useState(false);
  const [fecha, setFecha] = useState(dayjs());
  const [Values, setValues] = useState({
    id: id ?? 0,
    comentarios: "",  
  });
  const [Errores, setErrores] = useState({
    comentarios: false, 
  });
  const handleClose = () => {
    setOpen(false);
  };
  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value ? target.value : "";
    setValues((prevValues) => ({
      ...prevValues,
      [Name]: Value,
    }));
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };
  useEffect(() => {
    setIsLoading(true);
    if (id != 0) {
      getInfoVenta(id)
        .then((resp) => {
          if (resp?.err?.length == 0) {
            iziToast.error({
              title: "ERROR",
              message: "Ha ocurrido un error, intenta nuevamente ",
              position: "topCenter",
            });
            setOpen(false);
          } else {
            setValues(resp);
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          iziToast.error({
            title: "ERROR",
            message: "Ha ocurrido un error, intenta nuevamente ",
            position: "topCenter",
          });
          setOpen(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const GuardarCompra = () => {
    setIsGuardando(true);
    guardar(Values, id, fecha)
      .then((data) => {
        if (data.estatus == "OK") {
          iziToast.success({
            title: "OK",
            message: data.mensaje,
            position: "topCenter",
          });
          getListaAdmin();
          setIsGuardando(false);
          setOpen(false);
        } else {
          iziToast.error({
            title: "ERROR",
            message: data.mensaje,
            position: "topCenter",
          });
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        iziToast.error({
          title: "ERROR",
          message: data.mensaje,
          position: "topCenter",
        });

        alert(data.mensaje);
        setIsGuardando(false);
      });
  };

  const CAncelarCompra = () => {
    setIsGuardandoCan(true);
    cancelar(Values, id, fecha)
      .then((data) => {
        if (data.estatus == "OK") {
          iziToast.success({
            title: "OK",
            message: data.mensaje,
            position: "topCenter",
          });
          getListaAdmin();
          setIsGuardandoCan(false);
          setOpen(false);
        } else {
          iziToast.error({
            title: "ERROR",
            message: data.mensaje,
            position: "topCenter",
          });
          setIsGuardandoCan(false);
        }
      })
      .catch((data) => {
        iziToast.error({
          title: "ERROR",
          message: data.mensaje,
          position: "topCenter",
        });

        alert(data.mensaje);
        setIsGuardandoCan(false);
      });
  };
  

  React.useEffect(() => {
    const { current: descriptionElement } = descriptionElementRef;
    if (descriptionElement !== null) {
      descriptionElement.focus();
    }
  }, []);

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={"sm"}
        open={true}
        onClose={handleClose}
        scroll={"body"}
      >
        <div className="position-absolute" style={{ right: 0 }}>
          <Tooltip title="Cerrar">
            <IconButton onClick={handleClose} aria-label="Cerrar" size="large">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </div>

        <DialogTitle id="scroll-dialog-title">
          Pagar venta folio:{" "}
          {Number(id).toLocaleString("en-US", {
            minimumIntegerDigits: 5,
            useGrouping: false,
          })}
        </DialogTitle>
        <DialogContent dividers={"paper"}>
          {IsLoading ? (
            <>
              <div className="d-flex justify-content-center p-5 w-100">
                <CircularProgress color="success" />
                <span className="ms-3 align-self-center">Cargando...</span>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <div className="col-12  pb-3">
                  <TextField
                    fullWidth
                    name="comentarios"
                    label="Comentarios"
                    variant="outlined"
                    color="success"
                    value={Values.comentarios}
                    error={Errores.comentarios}
                    helperText={Errores.comentarios ? "comentarios no valido" : ""}
                    onChange={handlInputChange}
                  />
                </div>
                <div className="col-12  pb-3">
                  <DatePickerInput
                    name="fecha_fin"
                    label="Fecha de pago"
                    fecha={fecha}
                    error={Errores.fecha_fin}
                    helperText={Errores.fecha_fin ? "Fecha no valido" : ""}
                    setFecha={setFecha}
                  />
                </div>
                <div className="col-12  pb-3">
                  <LoadingButton
                    fullWidth
                    className="mt-3 py-3"
                    loading={IsGuardando}
                    loadingPosition="start"
                    disabled={IsLoading}
                    onClick={() => GuardarCompra()}
                    variant="contained"
                    color="success"
                    xs={{ with: "100$" }}
                  >
                    <span className={IsGuardando ? "px-4" : "px-2"}>
                      {IsGuardando ? "Guardando..." : "Marcar como pagado"}
                    </span>
                  </LoadingButton>
                  <LoadingButton
                    fullWidth
                    className="mt-3 py-3"
                    loading={IsGuardandoCan}
                    loadingPosition="start"
                    disabled={IsLoading}
                    onClick={() => CAncelarCompra()}
                    variant="contained"
                    color="error"
                    xs={{ with: "100$" }}
                  >
                    <span className={IsGuardandoCan ? "px-4" : "px-2"}>
                      {IsGuardandoCan ? "Guardando..." : "Cancelar compra "}
                    </span>
                  </LoadingButton>
                </div>
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}
