import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

const PreguntaasFrecuentes = () => {


  return (
    <div className='container-fluid container-sm mt-5' >

<h3 className="text-center mb-3">Preguntas frecuentes</h3>
      <Accordion className='shadow'>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">¿Dónde se ubican?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Ayotlán, Jalisco es nuestro hogar y estamos orgullosos de formar parte de esta vibrante comunidad. Nos dedicamos a impulsar el entretenimiento y la diversión a nivel local, colaborando con organizaciones benéficas y eventos comunitarios. Nos apasiona contribuir al crecimiento y bienestar de nuestra comunidad, y esperamos seguir construyendo lazos sólidos con nuestros amigos y vecinos en Ayotlán.</Typography>
        </AccordionDetails>
      </Accordion>
       <Accordion className='shadow'>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">¿Donde puedo consultar los boletos disponibles?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>En la opcion <span>comparar boleto</span> o en el siguiente  <Link to={"/comprarboleto"}>
       enlace     </Link></Typography>
        </AccordionDetails>
      </Accordion>
       <Accordion className='shadow'>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">¿Cuanto tiempo tengo para pagar mis boletos depues de apartarlos?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Tienes 12 horas para pagar y reportar tu pago por medio de WhatsApp </Typography>
        </AccordionDetails>
      </Accordion>

   <Accordion className='shadow'>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">¿Cómo se elige al ganador?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Todos nuestros sorteos se realizan en base a los resultados de los sorteos de la Lotería Nacional<br />
          El participante ganador de Sorteos RM será el participante cuyo número de boleto coincida con las últimas cinco cifras del premio mayor del sorteo de la LN en la fecha estipulada, así mismo, el boleto ganador debe estar completamente liquidado, la totalidad del pago debe verse reflejado en nuestras cuentas antes del comienzo del sorteo, si por alguna razón el pago ha sido devuelo, retenido o cualquier otro motivo, se tomará como inválido y no podrá ser acreedor al premio.
           </Typography>
        </AccordionDetails>
      </Accordion>


   <Accordion className='shadow'>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">¿Que sucede cuando el número ganador no fué vendido?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography> Se vuelve a sortear en otra fecha cercana con la misma dinámica.<br />
          Esto significa que, ¡Tendrías el doble de oportunidades de ganar con tu mismo boleto!           </Typography>
        </AccordionDetails>
      </Accordion>
      

       <Accordion className='shadow'>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">¿En dónde puedo descargar mi boleto?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>Para brindar mayor confianza a nuestros participantes y transparentar nuestros sorteos, los boletos son digitales y puedes tener la seguridad que tu boleto ha sido reservado, apartado o pagado, te invitamos a buscar cualquier boleto desde nuestro 
          <Link to={"/validador"}>
          Verificador de boletos haciendo clic aquí.  </Link>
          <br />
¡Descarga tu boleto!  </Typography>
        </AccordionDetails>
      </Accordion>
      
    </div>
  );
};

export default PreguntaasFrecuentes;