import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import "izitoast/dist/css/iziToast.css";
import DownloadIcon from "@mui/icons-material/Download";

import { IconButton, Tooltip } from "@mui/material";
import html2canvas from "html2canvas";
import CloseIcon from "@mui/icons-material/Close";
export default function Boleto({ setOpen, arrayData, row }) {
  console.log(row);
  const handleClose = () => {
    setOpen(false);
  };

  const handleDownload = (folio) => {
    const container = document.getElementById("mi-contenedor");
    html2canvas(container, { allowTaint: true, useCORS: true })
      .then((canvas) => {
        const imageDataURL = canvas.toDataURL();
        const link = document.createElement("a");
        link.href = imageDataURL;
        link.download = "Boleto_" + folio + ".png";
        link.click();
      })
      .catch((error) => {
        console.error("Error al generar la imagen:", error);
      });
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={true}
        onClose={handleClose}
        scroll={"body"}
      >
        <div className="position-absolute" style={{ right: 0 }}>
          <Tooltip title="Cerrar">
            <IconButton onClick={handleClose} aria-label="Cerrar" size="large">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </div>

        <DialogTitle id="scroll-dialog-title">Tu boleto</DialogTitle>
        <DialogContent dividers={"paper"}>
          <>
            <div className="row">
              <div className="col-12 col-md-6  order-1 order-md-0">
                {
                  <>
                    <div className="">
                      <div
                        id="mi-contenedor"
                        style={{
                          position: "relative",
                          width: "320px",
                          height: "640px",
                          overflow: "hidden",
                          backgroundImage:
                            "url(" + arrayData.sorteo[0].BOLETO + ")",
                        }}
                      >
                        <p
                          className="position-absolute text-white"
                          style={{
                            left: "119px",
                            fontSize: "30px",
                            top: "128px",
                          }}
                        >
                          {Number(row?.FOLIO).toLocaleString("en-US", {
                            minimumIntegerDigits: 5,
                            useGrouping: false,
                          })}
                        </p>

                        <p
                          className="position-absolute "
                          style={{
                            left: "19px",
                            fontSize: "14px",
                            top: "225px",
                          }}
                        >
                          {row?.NOMBRE + " " + row?.APELLIDO}
                        </p>

                        <p
                          className="position-absolute "
                          style={{
                            left: "19px",
                            fontSize: "14px",
                            top: "284px",
                          }}
                        >
                          {row?.MUN + ", " + row?.ESTADO}
                        </p>
                        <p
                          className="position-absolute "
                          style={{
                            left: "19px",
                            fontSize: "14px",
                            top: "345px",
                          }}
                        >
                          {row?.TELEFONO}
                        </p>
                        <p
                          className="position-absolute "
                          style={{
                            left: "19px",
                            fontSize: "12px",
                            top: "362px",
                          }}
                        >
                          {row?.CORREO}
                        </p>
                        <p
                          className="position-absolute "
                          style={{
                            left: "19px",
                            fontSize: "15px",
                            top: "408px",
                          }}
                        >
                          {row?.FECHA_COMPRA}
                        </p>
                      </div>
                    </div>
                  </>
                }
              </div>
              <div className="col-12 col-md-6  order-md-1 order-0">
                <Button
                  style={{ backgroundColor: "#7C8240" }}
                  fullWidth
                  size="large"
                  onClick={() => {
                    handleDownload(
                      Number(row?.FOLIO).toLocaleString("en-US", {
                        minimumIntegerDigits: 5,
                        useGrouping: false,
                      })
                    );
                  }}
                  className="mt-2 mb-4"
                  variant="contained"
                  color="success"
                >  Haz clic aquí para descargar{" "}
                          <DownloadIcon
                            style={{ width: "23px" }}
                            className="ms-2"
                          />
                </Button>
              </div>
            </div>
          </>
        </DialogContent>
        <DialogActions>
          <div>
            <Button
              className="w-100-sm me-0 me-sm-3"
              size="large"
              variant="outlined"
              onClick={handleClose}
            >
              Cancelar
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
