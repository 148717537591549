import * as React from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function CircularLabel(
  props ,
) {
 
}

export default function CircularWithValueLabel({progres, value}) { 
      
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress 
           size={100}  color='success' variant="determinate"   value={`${Math.round(Number(progres))}`}  />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant="h5"
              component="div" 
              color="text.secondary"
            >{value}</Typography>
          </Box>
        </Box>
      );
}