import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import AppsIcon from "@mui/icons-material/Apps";
import NotificationsNoneRoundedIcon from "@mui/icons-material/NotificationsNoneRounded";
import { clearStorageJWT, getLocalStorage } from "../Contextos/Storage";

import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import CloseIcon from "@mui/icons-material/Close";
import { DialogContentText } from "@mui/material";
import CerrarSesion from "./Dialogos/CerrarSesion";

 

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showDialogoCerrarSesion, setShowDialogoCerrarSesion] =
    React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {showDialogoCerrarSesion && (
        <CerrarSesion setShowDialogoCerrarSesion={setShowDialogoCerrarSesion} />
      )} 
      <React.Fragment>
        <Box
          sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        >
          <div
            className="text-truncate d-none d-md-block"
            style={{ maxWidth: "100px" }}
          >
            <span>{getLocalStorage("NOMBRE")}</span>
          </div>
          <Tooltip title="Mi cuenta">
            <IconButton
              onClick={handleClick}
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                alt={getLocalStorage("NOMBRE")}
                sx={{ width: 32, height: 32 }}
              >
                {getLocalStorage("NOMBRE").substring(0, 1)}
              </Avatar>
            </IconButton>
          </Tooltip>
        </Box>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={handleClose}>
            <Avatar alt={getLocalStorage("NOMBRE")}>
              {getLocalStorage("NOMBRE").substring(0, 1)}
            </Avatar>
            {getLocalStorage("NOMBRE")} <br />
            {getLocalStorage("CORREO")}
          </MenuItem>

          <Divider />
          <MenuItem
            onClick={() => {
              setShowDialogoCerrarSesion(true);
            }}
          >
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Cerrar sesión
          </MenuItem>
        </Menu>
      </React.Fragment>
    </>
  );
}
