import { useContext } from "react";
import { AppContext } from "./Contextos/AppContext"; 
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import logo from "./Assets/facebook.png";


export default function Contacto() {
  const { arrayData } = useContext(AppContext);

  console.log(arrayData);
  return (
    <>
      <div className="container-fluid p-5 container-verde">
        <h1
          className="fw-bold text-center  mb-4 "
          style={{
            letterSpacing: "4px",
            color: "#faff00",
          }}
        >
          CONTÁCTANOS
        </h1>

        {arrayData.cnfigs[0].WHATSAPP1 != null &&
          arrayData.cnfigs[0].WHATSAPP1 != "" && (
            <a
              href={"https://wa.me/" + arrayData.cnfigs[0].WHATSAPP1}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <h1 className="fw-bold text-center text-white mb-4">
                <WhatsAppIcon style={{ fontSize: "2.3rem" }} />{" "}
                {arrayData.cnfigs[0].WHATSAPP1}
              </h1>
            </a>
          )}
        {arrayData.cnfigs[0].WHATSAPP2 != null &&
          arrayData.cnfigs[0].WHATSAPP2 != "" && (
            <a
              href={"https://wa.me/" + arrayData.cnfigs[0].WHATSAPP2}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <h1 className="fw-bold text-center text-white mb-4">
                <WhatsAppIcon style={{ fontSize: "2.3rem" }} />{" "}
                {arrayData.cnfigs[0].WHATSAPP2}
              </h1>
            </a>
          )}

        {arrayData.cnfigs[0].WHATSAPP3 != null &&
          arrayData.cnfigs[0].WHATSAPP3 != "" && (
            <a
              href={"https://wa.me/" + arrayData.cnfigs[0].WHATSAPP3}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <h1 className="fw-bold text-center text-white mb-4">
                <WhatsAppIcon style={{ fontSize: "2.3rem" }} />{" "}
                {arrayData.cnfigs[0].WHATSAPP3}
              </h1>
            </a>
          )}
        {arrayData.cnfigs[0].WHATSAPP4 != null &&
          arrayData.cnfigs[0].WHATSAPP4 != "" && (
            <a
              href={"https://wa.me/" + arrayData.cnfigs[0].WHATSAPP4}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <h1 className="fw-bold text-center text-white mb-4">
                <WhatsAppIcon style={{ fontSize: "2.3rem" }} />{" "}
                {arrayData.cnfigs[0].WHATSAPP4}
              </h1>
            </a>
          )}

        <a
          href={"mailto:soporte@sorteosrm.com" + arrayData.cnfigs[0].WHATSAPP4}
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <h4
            className="fw-bold text-center text-white mb-4"
            md={{ fontSize: "2rem" }}
            sm={{ fontSize: "1rem" }}
          >
            <AttachEmailIcon md={{ fontSize: "2.3rem" }} />{" "}
            soporte@sorteosrm.com
          </h4>
        </a>


        <a href="https://www.facebook.com/profile.php?id=100088642774433" 
    className="text- "
    style={{color:"white"}}
    target="_blank">  
      <div className="d-flex justify-content-center w-100 mt-5">
        <div className="d-flex ">
          <div>
            <img
              src={logo}
              style={{ maxWidth: "50px" }}
              className="img-fluid "
            />
          </div>
          <h4 className="mb-0 align-self-center ms-3">RM Sorteos Jalisco </h4>
        </div>
      </div>
      </a>
      </div>
    </>
  );
}
