import { TituloContext } from "../Contextos/TituloContext";
import { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Configs from "./Componentes/Configs";

export default function ConfigsIndex() {
  const { actualizarTitulo } = useContext(TituloContext);

  useEffect(() => {
    actualizarTitulo("Configuraciones");
    document.title = "Configuraciones - Sorteos RM";
  }, []);

  return (
    <>
      <div className="container-fluid p-0 p-sm-2  py-3">
        <Routes>
          <Route path="/" element={<Configs />}></Route>
        </Routes>
      </div>
    </>
  );
}
