import React, { useState, useEffect } from "react"; 
import Paper from "@mui/material/Paper";
import ReporteVentas from "./ReporteVentas";
import ReporteBoletos from "./ReporteBoletos";
 

export default function BasicTable() {
  

  return (
    <div className="mt-2"> 

<h6 className="text-muted mt-3">
        Los reportes se generan del sorteo activo
        </h6>

      <Paper className="shadow border"> 
        <ReporteVentas />
      </Paper>
      <Paper className="shadow border mt-4">
           <ReporteBoletos />
        </Paper>
    </div>
  );
}
