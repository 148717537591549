import * as React from "react";
import { MenuItem, TextField } from "@mui/material";

const Select = ({
  ValueSelect,
  Name,
  handlInputChange,
  opciones = [ ],
  titulo="Selecciona",
  size="medium",
  error=false,
  helperText =""
}) => {
  
  return (
    <TextField
    error={error}
    helperText={helperText}
    size={size}
    name={Name}
      fullWidth
      id="outlined-select-currency"
      select
      onChange={handlInputChange}
      label={titulo}
      value={ValueSelect} 
    >
      {opciones.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default Select;
