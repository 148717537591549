import { useContext } from "react";
import { AppContext } from "./Contextos/AppContext";
import Contacto from "./Contacto";
import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import * as React from "react";

import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import { useEffect } from "react";
import { getInfoBoleto } from "./Funciones/Sorteos";
import Footer from "./Includes/Footer";
import Boleto from "./Dialogos/Boleto";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Chip } from "@material-ui/core";
import DownloadIcon from "@mui/icons-material/Download";
import { BrowserRouter as Router, Route, useParams } from "react-router-dom";
import { queries } from "@testing-library/react";

export default function Validador() {
  const { arrayData } = useContext(AppContext);
  const [query, setQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showDialgBoleto, setShowDialgBoleto] = useState(false);
  const [showNoencontrado, setNoEncontrado] = useState(false);
  const [List, setList] = useState([]);
  const [row, setRow] = useState([]);
  let { valor } = useParams();


  const handleChangeBuscador = (event) => { 
    setQuery(event.target.value);  
  }; 


  const getListaCliente = (param="") => { 
    setIsLoading(true);
    setList([]);
    setNoEncontrado(false);
    getInfoBoleto(param==""?query:param)
      .then((resp) => {
        let list = JSON.parse(resp.objRespuesta);
        setList(list);
        setIsLoading(false);
        if (list.length == 0) {
          setNoEncontrado(true);
        } else {
          if (list.length == 1) {
          } else {
          }
          setNoEncontrado(false);
        }
      })
      .catch((resp) => {
        setList([]);
        setNoEncontrado(true);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (valor) {
      getListaCliente(valor);
      setQuery(valor);  
    }
  }, []);


  console.log("Pagado: "+row.PAGADO)

  return (
    <>
      {showDialgBoleto && (
        <Boleto setOpen={setShowDialgBoleto} arrayData={arrayData} row={row} />
      )}

      <div className="d-flex  justify-content-center w-100">
        <div
          style={{ maxWidth: "600px" }}
          className="contenedor-100 card shadow-lg border border-0 mt-5 mb-2 p-4 ms-0   mb-0    
  "
        >
          <div className="d-flex">
            <div className="me-3 align-self-center">
              <FormControl fullWidth variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Buscar por boleto o teléfono
                </InputLabel>
                <OutlinedInput
                  id="Buscar"
                  size="small"
                  color="success"
                  placeholder={"Buscar"}
                  value={query}
                  onChange={handleChangeBuscador}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                  label=" Buscar por boleto o teléfono"
                />
              </FormControl>
            </div>
            <div className=" align-self-center">
              <Button
                className=""
                variant="contained"
                style={{ backgroundColor: "#7C8240" }}
                onClick={()=>{
                  getListaCliente("")
                }}
              >
                Buscar <SearchIcon style={{ width: "18px" }} className="ms-1" />
              </Button>
            </div>
          </div>
          {isLoading && (
            <center>
              <CircularProgress className="mt-5 mb-5" color="success" />
            </center>
          )}
          {showNoencontrado && (
            <div className="mt-3">
              <Alert severity="error">Boleto no encontrado</Alert>
            </div>
          )}
        </div>
      </div>
      <div className="d-flex  justify-content-center w-100">
        <div className="p-2 p-md-5 ">
          {List.length != 0 && (
            <Paper
              className="shadow-lg"
              sx={{ width: "100%", overflow: "hidden" }}
            >
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="fw-bold" align="left">
                        Descargar
                      </TableCell>
                      <TableCell className="fw-bold">Boleto</TableCell>
                      <TableCell className="fw-bold" align="left">
                        Nombre
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {List?.map((row) => (
                      <TableRow
                        key={row.FOLIO}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left">
                          {
                            row.PAGADO ?
                            <Button
                            color="success"
                            variant="contained"
                            style={{ backgroundColor: "#7C8240" }}
                            onClick={() => {
                              setRow(row);
                              setShowDialgBoleto(true);
                            }}
                          >
                            Descargar{" "}
                            <DownloadIcon
                              style={{ width: "18px" }}
                              className="ms-1"
                            />
                          </Button>
                          :


                          <Chip label={"Apartado"} 
                          color="default"
                          style={{
                            backgroundColor:""
                          }} /> 
                         
                          }
                      
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Chip label={row.FOLIO} color="primary" />
                        </TableCell>
                        <TableCell align="left">
                          {row.NOMBRE + " " + row.APELLIDO}
                          <br />
                          {row.ESTADO}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
        </div>
      </div>
      <Contacto />
      <Footer />
    </>
  );
}
