import { getLocalStorageJWT, clearStorageJWT } from "./Storage";
import React, { useState, useEffect } from "react";

export const BACKEND_URL =
  process.env.NODE_ENV === "production"
    ? "https://felixdev.cloud/sorteosrm/api/" //"https://sorteosrm.azurewebsites.net/api/"
    : "http://localhost:7053/api/";

 
export function postdData(url, body) {
  let bearer_token = getLocalStorageJWT();
  try {
    return fetch(BACKEND_URL + url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: "Bearer " + bearer_token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status != 200) {
          console.log(response);
          return {
            resp: {
              codigo: String(response.status),
              mensaje: "Error: " + response.statusText,
            },
          };
        }
        return response.json();
      })
      .then((response) => {
        //console.log(response);
        if (response.estatus == "OK") {
          return { error: false, data: response };
        } else {
          if (response.estatus == "403") {
            clearStorageJWT();
          } else {
            return {
              error: true,
              mensaje:
                response.mensaje + " (" + response.estatus + ")",
            };
          }
        }
      })
      .catch((error) => {
        console.log(error);
        return { error: true, mensaje: error };
      });
  } catch (error) {
    console.log(error);
  }
}

export function postUrl(url) {
  let bearer_token = getLocalStorageJWT();
  return fetch(BACKEND_URL + url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + bearer_token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.status != 200) {
        console.log(response);
        return {
          resp: {
            codigo: String(response.status),
            mensaje: "Error: " + response.statusText,
          },
        };
      }
      return response.json();
    })
    .then((response) => {
      if (response.estatus == "OK") {
        return { error: false, data: response };
      } else {
        if (response.estatus == "403") {
          clearStorageJWT();
        } else {
          return {
            error: true,
            mensaje:
              response.mensaje + " (" + response.estatus + ")",
          };
        }
      }
    })
    .catch((error) => {
      console.log(error);
      return { error: true, mensaje: error };
    });
}

export const getData = (url) => {
  let bearer_token = getLocalStorageJWT();
  return (
    fetch(BACKEND_URL + url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + bearer_token,
        "Content-Type": "application/json",
      },
    }) 
      .then((response) => { 
        if (response.status == 401) {
          clearStorageJWT();
        }
        if (response.status != 200) {
        
          return {
            resp: {
              codigo: String(response.status),
              mensaje: "Error: " + response.statusText,
            },
          };
        }
        //console.log(response.status);
        return response.json();
      })
      .then((response) => {
       //  console.log(response);
        if (response.estatus == "OK") {
          return { error: false, data: response };
        } else {
          if (response.estatus == "403") {
            clearStorageJWT();
          } else {
            return {
              error: true,
              mensaje:
                response.mensaje + " (" + response.estatus + ")",
            };
          }
        }
      })
      .catch((error) => { 
        return { error: true, mensaje: error };
      })
  );
};
export default BACKEND_URL;
