import React, { createContext, useState } from 'react';

// Crea el contexto global
export const TituloContext = createContext();

// Crea el proveedor del contexto
export const TituloProvider = ({ children }) => {
  // Estado para almacenar el título
  const [titulo, setTitulo] = useState('Dasboard');
  const actualizarTitulo = (nuevoTitulo) => {
    setTitulo(nuevoTitulo);
  };

  return (
    <TituloContext.Provider value={{ titulo, actualizarTitulo }}>
      {children}
    </TituloContext.Provider>
  );
  
};