import { useState } from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import logo from "../Assets/Logo.png";
import menu from "../Assets/MENU.png";
import { IconButton } from "@mui/material";
import { AppProvider, AppContext } from "../Contextos/AppContext";
import { useContext } from "react";
import { getSorteoActivo } from "../Funciones/Sorteos";
import { useEffect } from "react";
import iziToast from "izitoast";

export default function DataSorteo() {
  const { arrayData, updateArrayData, isLoading, setIsLoading } =
    useContext(AppContext);

  const getInfoSorteo = () => {
    setIsLoading(true);
    getSorteoActivo()
      .then((resp) => {
        if (resp?.err?.length == 0) {
          iziToast.error({
            title: "ERROR",
            message: "Ha ocurrido un error, intenta nuevamente ",
            position: "topCenter",
          });
        } else {
          let Resp = JSON.parse(resp.objRespuesta);
          console.log(Resp);
          updateArrayData({
            sorteo: JSON.parse(Resp.sorteo),
            cnfigs: JSON.parse(Resp.config),
            formas: JSON.parse(Resp.formas),
            precios: JSON.parse(Resp.precios),
          });
          setIsLoading(false);
        }
      })
      .catch((resp) => {
        iziToast.error({
          title: "ERROR",
          message: "Ha ocurrido un error, intenta nuevamente ",
          position: "topCenter",
        });
      });
  };
  useEffect(() => {
    getInfoSorteo();
  }, []);

  return (
    <>
     
    </>
  );
}
