import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Row from "./Row";
import { Box, Button, CircularProgress, Tooltip } from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import FilterListIcon from "@mui/icons-material/FilterList";
import { getLista } from "../../Funciones/Precios";
import DialogoPrecio from "../../Dialogos/DialogoPrecio";
import Generales from "../Generales";

export default function Precios() {
  const [IsLoading, setIsLoading] = useState(true);
  const [Lista, setLista] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  useEffect(() => {
    getListaAdmin();
  }, []);

  const getListaAdmin = () => {
    setIsLoading(true);
    getLista()
      .then((resp) => {
        let list = JSON.parse(resp.objRespuesta);
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };

  return (
    <div>
      {openDialog && <DialogoPrecio setOpen={setOpenDialog} id={0}  getListaAdmin={getListaAdmin}/>} 
     
     <div className="d-flex justify-content-between flex-wrap w-100">
        <Button
          onClick={() => {
            setOpenDialog(true);
          }}
          color="success"
          className="mb-3"
          variant="contained"
          startIcon={<AddOutlinedIcon />}
        >
          Nuevo precio
        </Button>
        <div>
         <h5>Precios boletos</h5>
        </div>
      </div>

      <Paper className="shadow border">
        <TableContainer>
          <Table size="small" aria-label="simple table">
            <TableHead className="bg-s" style={{backgroundColor:"#e6e6e6"}}>
              <TableRow>
                <TableCell width={40} className="fw-bolder" align="left">
                  {" "}
                </TableCell>
                <TableCell className="fw-bolder" align="left">
                FECHA
                </TableCell>
                <TableCell
                  className="fw-bolder"
                  align="left"
                >
                 CANTIDAD
                </TableCell>
                
                <TableCell
                  className="fw-bolder "
                  align="right"
                >
                  PRECIO
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {IsLoading ? (
                <TableCell colSpan={5}>
                  <div className="d-flex justify-content-center p-5 w-100">
                    <CircularProgress color="success" />
                    <span className="ms-3 align-self-center">Cargando...</span>
                  </div>
                </TableCell>
              ) : (
                Lista.length==0?
                <TableCell colSpan={5}>
                <div className="d-flex justify-content-center p-4 w-100"> 
                  <span className="ms-3 align-self-center">Sin registros</span>
                </div>
              </TableCell>
              :
                Lista.map((row) => {
                  return <Row row={row}  getListaAdmin={getListaAdmin}/>;
                })
              )}
            </TableBody>
            
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
