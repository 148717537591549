import React, { useState, useEffect } from "react";
import Select from "../../Includes/Select";
import dayjs from "dayjs";
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { DatePickerInput } from "../../Includes/DatePicker";
import BACKEND_URL from "../../Contextos/backend";
import { ConvertDate } from "../../../Includes/Generales";
import { stringify } from "query-string";
import DownloadIcon from "@mui/icons-material/Download";

export default function ReporteBoletos() {
  const [FolioIn, setFolioIn] = useState(0);
  const [FolioFin, setFoliofin] = useState(10000);

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value ? target.value : "";

    const regex = /^[0-9]*$/; // Expresión regular para aceptar solo números enteros

    if (regex.test(Value)) {  
    if (Name == "folio_in") {
      setFolioIn(Value);
    }
    if (Name == "folio_fin") {
      setFoliofin(Value);
    }
}
  };

  const goRep = () => {
    let queryParamsObj = {
      FOLIO_IN: FolioIn,
      FOLIO_FIN: FolioFin,
    };
    const url =
      BACKEND_URL + `ventas/ReporteExcelBoletos?${stringify(queryParamsObj)}`;
    window.open(url, "_blank");
  };
  return (
    <div className="p-1 p-md-4">
      <h4 className="fw-bold">Reporte de boletos vendidos</h4>
      <div className="d-flex justify-content-star flex-wrap  w-100 mb-3">
        <div className="mt-3 me-0 me-sm-2" style={{ width: "170px" }}>
          <TextField
          size="small"
            fullWidth
            name="folio_in"
            label="Folio inicial"
            variant="outlined"
            color="success"
            value={FolioIn}
            onChange={handlInputChange}
          />
        </div>
        <div className="mt-3 me-0 me-sm-2" style={{ width: "170px" }}>
          <TextField
             size="small"
            fullWidth
            name="folio_fin"
            label="Folio inicial"
            variant="outlined"
            color="success"
            value={FolioFin}
            onChange={handlInputChange}
          />
        </div>
      </div>

      <div>
        <div className="w-100 d-flex justify-content-end">
          <Button
            onClick={() => {
              goRep();
            }}
            color="success"
            className="mb-0"
            variant="contained"
            endIcon={<DownloadIcon />}
          >
            Descargar reporte excel
          </Button>
        </div>
      </div>
    </div>
  );
}
