import { stringify } from "query-string";
import { getData, postdData, postUrl } from "../../Contextos/backend";
import validator from "validator";
 
///////////////////
/* FUNCIONES GET */
///////////////////
export const getLista = async () => {
 
  let data = [];
  let queryParamsObj = { 
  };  
  const url = `usuarios/Listar`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) { 
      data = res.data;
      resolve(data);
    } else {
      reject(res);
    }
  });
};
 

export const getInfoUsuario = async (id) => {
  let data = []; 
  const url = `usuarios/Consultar?id=`+id;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) { 
      data = JSON.parse(res.data.objRespuesta); 
      console.log(data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de este cliente",
        });
      } else {
        data = data[0];
        console.log(data);
        let Values = {
          id: String(data.ID),
          nombre: String(data.NOMBRE), 
          correo: String(data.CORREO),
          telefono: String(data.TELEFONO) 
        }; 
        resolve(Values);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};

////////////////////
/* FUNCIONES POST */
////////////////////
export const guardar = async (Values, Errores, setErrores) => {
  let error = false;

  /* REQUERIDOS */
  if (!Values.nombre || Values.nombre == "") {
    let Errores2 = Errores; 
    Errores2.nombre = true;
    setErrores(Errores2);
    error = true;
  }
 
  if  ( Values.id == 0) {

  if (!Values.contraseña || Values.contraseña == "") {
    Errores.contraseña = true;
    setErrores(Errores);
    error = true;
  }  
}
else
{
  if (Values.contraseña==undefined) {
    Values.contraseña="";
  }  
  
}
   
  if (!Values.correo || Values.correo == "") {
    Errores.correo = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isEmail(Values.correo)) {
      Errores.correo = true;
      setErrores(Errores);
      error = true;
    }
  }

  if (!Values.telefono || Values.telefono == "") {
    Errores.telefono = true;
    setErrores(Errores);
    error = true;
  } else {
    if (!validator.isNumeric(Values.telefono)) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    } else if (Values.telefono.length != 10) {
      Errores.telefono = true;
      setErrores(Errores);
      error = true;
    }
  } 

 

  if (!error) {
     
    let data = [];
    const body = Values;
    console.log("Datos", body);
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "usuarios/Insertar"
        : "usuarios/Editar";

    const res = await postdData(url, body);
    console.log(res);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data; 
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const Eliminar = async (id) => {
  if (id != undefined) {
    let data = []; 
    const url = `usuarios/Eliminar?id=`+id;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
}; 