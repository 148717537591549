import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import dayjs from "dayjs";
import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.css';

import {
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { getInfoUsuario, guardar } from "../Funciones/Sorteos";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import { DatePickerInput } from "../../Includes/DatePicker";

export default function DialogoUsuario({ setOpen, id = 0, getListaAdmin }) {
  const descriptionElementRef = useRef(null);
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [fecha, setFecha] = useState(dayjs());

  const [Values, setValues] = useState({
    id: id ?? 0,
    nombre: "",
    descripcion_sorteo: "",
    fecha_fin: dayjs(),
    precio_boleto: 0,
    folio_inicial: 1,
    max_boletos: 1000,
  });
  const [Errores, setErrores] = useState({
    nombre: false,
    descripcion_sorteo: false,
    fecha_fin: false,
    precio_boleto: false,
    folio_inicial: false,
    max_boletos: false,
  });
  const handleClose = () => {
    setOpen(false);
  };
  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value ? target.value : "";

    if(Name=="precio_boleto" || Name=="folio_inicial"  || Name=="max_boletos"  )
    {

      const regex = /^[0-9]*$/; // Expresión regular para aceptar solo números enteros

      if (regex.test(Value)) {
        setValues((prevValues) => ({
          ...prevValues,
          [Name]:Number(Value),
        }));
      }


    }
    else
    {
      setValues((prevValues) => ({
        ...prevValues,
        [Name]: Value,
      }));
    }
   
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };
  useEffect(() => {
    setIsLoading(true);
    if (id != 0) {
      getInfoUsuario(id)
        .then((resp) => {
          if (resp?.err?.length == 0) {
             iziToast.error({
            title: 'ERROR',
            message: "Ha ocurrido un error, intenta nuevamente ",
            position: 'topCenter',
          });
            setOpen(false);
          } else {
            setValues(resp);
            setFecha(resp.fecha_fin)
            setIsLoading(false);
          }
        })
        .catch((resp) => {
          iziToast.error({
            title: 'ERROR',
            message: "Ha ocurrido un error, intenta nuevamente ",
            position: 'topCenter',
          });
          setOpen(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  const GuardarUsusario = () => {
    setIsGuardando(true);
    
    const currentDate = dayjs();
  const formattedDate = currentDate.format('YYYY-MM-DD');
Values.fecha_fin  = formattedDate;


    guardar(Values, Errores, setErrores, fecha)
      .then((data) => { 
        if (data.estatus == "OK") {
          iziToast.success({
            title: 'OK',
            message: data.mensaje,
            position: 'topCenter',
          }); 
          getListaAdmin();
          setIsGuardando(false);
          setOpen(false);
         
        } else {
          setIsGuardando(false);
          iziToast.error({
            title: 'ERROR',
            message: data.mensaje,
            position: 'topCenter',
          });
        }
      })
      .catch((data) => {
        iziToast.error({
          title: 'ERROR',
          message: data.mensaje,
          position: 'topCenter',
        });  
        setIsGuardando(false);
      });
  };

  React.useEffect(() => {
    const { current: descriptionElement } = descriptionElementRef;
    if (descriptionElement !== null) {
      descriptionElement.focus();
    }
  }, []);

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={true}
        onClose={handleClose}
        scroll={"body"}
      >
        <div className="position-absolute" style={{ right: 0 }}>
          <Tooltip title="Cerrar">
            <IconButton onClick={handleClose} aria-label="Cerrar" size="large">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </div>

        <DialogTitle id="scroll-dialog-title">
          {id == 0 ? "Nuevo sorteo" : "Editar sorteo"}
        </DialogTitle>
        <DialogContent dividers={"paper"}>
          {IsLoading ? (
            <>
              <div className="d-flex justify-content-center p-5 w-100">
                <CircularProgress color="success" />
                <span className="ms-3 align-self-center">Cargando...</span>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <div className="col-12 col-md-8 pb-3">
                  <TextField
                    fullWidth
                    name="nombre"
                    label="Nombre"
                    variant="outlined"
                    color="success"
                    value={Values.nombre}
                    error={Errores.nombre}
                    helperText={Errores.nombre ? "Nombre no valido" : ""}
                    onChange={handlInputChange}
                  />
                </div>
                <div className="col-12 col-md-4 pb-3">
                  <DatePickerInput
                    name="fecha_fin"
                    label="Fecha de sorteo"
                    fechaMin={dayjs()}
                    fecha={fecha}
                    error={Errores.fecha_fin}
                    helperText={Errores.fecha_fin ? "Fecha no valido" : ""}
                    setFecha={setFecha}
                  />
                </div>
                <div className="col-12 col-md-4 pb-3">
                  <TextField
                    fullWidth
                    name="precio_boleto"
                    label="Precio de boleto"
                    variant="outlined"
                    color="success"
                    value={Values.precio_boleto}
                    error={Errores.precio_boleto}
                    helperText={Errores.precio_boleto ? "Precio_boleto no valido" : ""}
                    onChange={handlInputChange}
                    inputProps={{
                      pattern: '[0-9]*', // Patrón HTML para aceptar solo números enteros
                      inputMode: 'numeric', // Modo de entrada numérica
                    }}
                  />
                </div>

                <div className="col-12 col-md-4 pb-3">
                  <TextField
                    fullWidth
                    name="folio_inicial"
                    label="Folio inical"
                    variant="outlined"
                    color="success"
                    disabled={id!=0}
                    value={Values.folio_inicial}
                    error={Errores.folio_inicial}
                    helperText={Errores.folio_inicial ? "Folio no valido" : ""}
                    onChange={handlInputChange}
                    inputProps={{
                      pattern: '[0-9]*', // Patrón HTML para aceptar solo números enteros
                      inputMode: 'numeric', // Modo de entrada numérica
                    }}
                  />
                </div>

                <div className="col-12 col-md-4 pb-3">
                  <TextField
                    fullWidth
                    name="max_boletos"
                    label="Max. boletos"
                    variant="outlined"
                    color="success"
                    disabled={id!=0}
                    value={Values.max_boletos}
                    error={Errores.max_boletos}
                    helperText={
                      Errores.max_boletos ? "Max. boletos no valido" : ""
                    }
                    onChange={handlInputChange}
                    inputProps={{
                      pattern: '[0-9]*', // Patrón HTML para aceptar solo números enteros
                      inputMode: 'numeric', // Modo de entrada numérica
                    }}
                  />
                </div>

                <div className="col-12  ">
                  <TextField
                    fullWidth
                    name="descripcion_sorteo"
                    label="Descripción del sorteo"
                    variant="outlined"
                    color="success"
                    multiline={true}
                    maxRows={3}
                    value={Values.descripcion_sorteo}
                    error={Errores.descripcion_sorteo}
                    helperText={
                      Errores.descripcion_sorteo ? "Descripción no valido" : ""
                    }
                    onChange={handlInputChange}
                  />
                </div>
                <div className="col-12 mt-4 ">
<h6 className="text-muted">Nota: Al crear el sorteo no será posible modificar el folio inicial y el máximo de boletos</h6>
                  </div>
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <LoadingButton
            loading={IsGuardando}
            loadingPosition="start"
            disabled={IsLoading}
            onClick={() => GuardarUsusario()}
            variant="contained"
            color="success"
            xs={{ with: "100$" }}
          >
            <span className={IsGuardando ? "px-4" : "px-2"}>
              {IsGuardando ? "Guardando..." : id ? "Guardar" : "Crear"}
            </span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
