import { Link } from "react-router-dom";
import img404 from "./Assets/Error404.jpg";
import { Button } from "@mui/material";

export default function P404() {
  return (
    <>
      <div
        style={{ height: "100vh" }}
        className="w-100 mt-5 h-100 d-flex flex-column justify-content-center align-content-center align-items-center"
      >
        <div className="align-self-center">
          <img
            src={img404}
            className="img-fluid"
            style={{ maxWidth: "500px" }}
          />{" "}
        </div>
        <Link to="/" style={{ textDecoration: "none" }}> 
                  <Button className=" w-100 mt-5 px-5 color-degradado btn-redondeado"> 
                   Regresar a inicio
                  </Button>
                  </Link>
      </div>
    </>
  );
}
