import { borderRadius, margin, width } from "@mui/system";

import logo from "../../Assets/Isotipo.png";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  LinearProgress,
  Skeleton,
} from "@mui/material";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import { Link } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PaidIcon from "@mui/icons-material/Paid";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { TituloContext } from "./../Contextos/TituloContext";
import { useContext, useEffect, useState } from "react";
import iziToast from "izitoast";
import "izitoast/dist/css/iziToast.css";
import { getInfoConfigs, gtedash } from "../Configs/Funciones/Configuraciones";
import { formatNumber } from "../../Includes/Generales";

export default function Dasboard() {
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [Values, setValues] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    gtedash()
      .then((resp) => {
        if (resp?.err?.length == 0) {
          console.log(resp);
          iziToast.error({
            title: "ERROR",
            message: "Ha ocurrido un error, intenta nuevamente ",
            position: "topCenter",
          });
        } else {
          console.log(resp.sorteo);
          resp.sorteo = resp.sorteo ? JSON.parse(resp.sorteo) : [];
          resp.sorteo = resp.sorteo.length != 0 ? resp.sorteo[0] : resp.sorteo;

          console.log(resp);
          setValues(resp);
          setIsLoading(false);
        }
      })
      .catch((resp) => {
        iziToast.error({
          title: "ERROR",
          message: "Ha ocurrido un error, intenta nuevamente. ",
          position: "topCenter",
        });
      });
  }, []);

  const { actualizarTitulo } = useContext(TituloContext);

  useEffect(() => {
    actualizarTitulo("Dashboard");
    document.title = "Dashboard - Sorteos RM";
  }, []);

  return (
    <>
      <div className="container-fluid  py-3">
        {IsLoading ? (
          <center>
            <CircularProgress color="success" />
          </center>
        ) : (
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4">
              <Card elevation={5} className="mb-3 shadow-lg border">
                <CardContent>
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6 className="text-muted">Sorteo actual</h6>
                      <h5 className="fw-bolder mb-0">
                        {Values.sorteo.NOMBRE ? Values.sorteo.NOMBRE : "..."}
                      </h5>
                    </div>
                    <div>
                      <Avatar className="bg-primary">
                        <EventAvailableIcon />
                      </Avatar>
                    </div>
                  </div>
                </CardContent>
                <CardActions className="text-end">
                  <Link
                    className="text-end w-100"
                    to={"sorteos"}
                    style={{ textDecoration: "none" }}
                  >
                    <Button className="text-black" fullWidth>
                      ir a sorteos
                      <KeyboardArrowRightIcon />
                    </Button>
                  </Link>
                </CardActions>
              </Card>
            </div>

            <div className="col-12 col-md-6 col-lg-4">
              <Card elevation={5} className="mb-3 shadow-lg border">
                <CardContent>
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6 className="text-muted">Ventas realizadas</h6>
                      <h3 className="fw-bolder mb-0">
                        {Values.Ventas ? formatNumber(Values.Ventas) : "..."}
                      </h3>
                    </div>
                    <div>
                      <Avatar className="bg-primary">
                        <PaidIcon />
                      </Avatar>
                    </div>
                  </div>
                </CardContent>
                <CardActions className="text-end">
                  <Link
                    className="text-end w-100"
                    to={"ventas"}
                    style={{ textDecoration: "none" }}
                  >
                    <Button className="text-black" fullWidth>
                      Ver ventas
                      <KeyboardArrowRightIcon />
                    </Button>
                  </Link>
                </CardActions>
              </Card>
            </div>

            <div className="col-12 col-md-6 col-lg-4">
              <Card elevation={5} className="mb-3 shadow-lg border">
                <CardContent>
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6 className="text-muted">Restante para sorteo</h6>
                      <h3 className="fw-bolder mb-0">
                        {Values.sorteo.DIAS ? Values.sorteo.DIAS : "0"} Días
                      </h3>
                    </div>
                    <div>
                      <Avatar className="bg-primary">
                        <AccessTimeFilledIcon />
                      </Avatar>
                    </div>
                  </div>
                </CardContent>
                <CardActions className="text-end">
                <Link className="text-end w-100" 
              to={"sorteos"}
              style={{ textDecoration: "none" }}
            >
                  <Button className="text-black" fullWidth href="./Sorteos">
                    ir a sorteos
                    <KeyboardArrowRightIcon />
                  </Button>
                  </Link>
                </CardActions>
              </Card>
            </div>

            <div className="col-12 col-md-6 col-lg-4">
              <Card className="mb-3 shadow-lg border">
                <CardContent>
                  <div className="d-flex justify-content-between mb-4">
                    <div>
                      <h6 className="text-muted">Boeltos vendidos</h6>
                      <h3 className="fw-bolder mb-0">
                        {Values.Vendidos
                          ? formatNumber(Values.Vendidos)
                          : "..."}
                      </h3>
                    </div>
                    <div>
                      <Avatar className="bg-success">
                        <ConfirmationNumberIcon />
                      </Avatar>
                    </div>
                  </div>
                  <LinearProgress
                    color="success"
                    variant="determinate"
                    value={
                      Values.Vendidos
                        ? (Values.Vendidos * 100) / Values.sorteo.MAX_BOLETOS
                        : 0
                    }
                  />
                </CardContent>
              </Card>
            </div>

            <div className="col-12 col-md-6 col-lg-4">
              <Card elevation={5} className="mb-3 shadow-lg border">
                <CardContent>
                  <div className="d-flex justify-content-between mb-4">
                    <div>
                      <h6 className="text-muted">Boeltos apartados</h6>
                      <h3 className="fw-bolder mb-0">
                        {Values.ApartadosBoletos
                          ? formatNumber(Values.ApartadosBoletos)
                          : "..."}
                      </h3>
                    </div>
                    <div>
                      <Avatar className="bg-info">
                        <ConfirmationNumberIcon />
                      </Avatar>
                    </div>
                  </div>
                  <LinearProgress
                    color="success"
                    variant="determinate"
                    value={
                      Values.ApartadosBoletos
                        ? (Values.ApartadosBoletos * 100) /
                          Values.sorteo.MAX_BOLETOS
                        : 0
                    }
                  />
                </CardContent>
              </Card>
            </div>

            <div className="col-12 col-md-6 col-lg-4">
              <Card elevation={5} className="mb-3 shadow-lg border">
                <CardContent>
                  <div className="d-flex justify-content-between mb-4">
                    <div>
                      <h6 className="text-muted">Boeltos restantes</h6>
                      <h3 className="fw-bolder mb-0">
                        {Values.ApartadosBoletos
                          ? formatNumber(
                              Values.sorteo.MAX_BOLETOS - Values.Vendidos
                            )
                          : "..."}
                      </h3>
                    </div>
                    <div>
                      <Avatar className="bg-warning">
                        <ConfirmationNumberIcon />
                      </Avatar>
                    </div>
                  </div>
                  <LinearProgress
                    color="success"
                    variant="determinate"
                    value={
                      Values.Vendidos
                        ? ((Values.sorteo.MAX_BOLETOS - Values.Vendidos) *
                            100) /
                          Values.sorteo.MAX_BOLETOS
                        : 0
                    }
                  />
                </CardContent>
              </Card>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
