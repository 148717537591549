import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Avatar, IconButton } from "@mui/material";
import logo from "../../Assets/Logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import AccountMenu from "./MenuPerfil";
import { TituloContext } from "./../Contextos/TituloContext";
import { useTransition, animated, useSpring } from "react-spring";

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export default function Header({
  sidebarMini,
  setSidebarMini,
  openMenuMovil,
  setPpenMenuMovil,
}) {
  const { titulo } = React.useContext(TituloContext);

  const transitions = useTransition(titulo, {
    key: titulo,
    from: { opacity: 0, transform: "translateX(-100%)" },
    enter: { opacity: 1, transform: "translateX(0%)" },
    leave: { opacity: 0, transform: "translateX(100%)" },
  });

  const animacion = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

 

  return (
    <AppBar
    className="blurry-container2 border-bottom"
      elevation={0}
      style={{ height: "65px", 
      backgroundColor: "transparent", color: "black",
      marginRight: "16px" , paddingLeft:"16px"}}
    >
      <div className="d-flex justify-content-between w-100 h-100">
        <div className="d-flex ">
          <div className="css-cveggr-MuiListItemIcon-root">
            <IconButton
              onClick={() => {
                setSidebarMini(!sidebarMini);
                setPpenMenuMovil(!openMenuMovil);
              }}
            >
              <MenuIcon fontSize="inherit" />
            </IconButton>
          </div>
          <div>
            <img
              src={logo}
              className="img-fluid d-none d-md-block"
              style={{ maxWidth: "110px" }}
            />
          </div>
        </div>

        <div
          style={{}}
          className="ms-0 ms-md-3  paddingx24px d-flex 
          justify-content-between align-content-center 
          align-items-center px-0 px-md-2 mt-0 w-100"
        >
          <div className="align-align-self-center">
            {transitions((style, item) => (
              <animated.h4
                className="text-dark fw-bold mb-0 mt-2"
                style={{ ...style, position: "absolute", top: 0 }}
                key={item}
              >
                {item}
              </animated.h4>
            ))}
          </div>

          <div>
            <AccountMenu /> 
          </div>
        </div>
      </div>
    </AppBar>
  );
}
