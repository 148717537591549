import { Button } from "@mui/material";
import React from "react";
import whatsapp from "./../Assets/whatsapp.png";
import { useContext } from "react";
import { AppContext } from "../Contextos/AppContext";

const WhatsAppButton = () => {
  const { arrayData, isLoading } = useContext(AppContext);
  return (
    <a
      href={
        "https://api.whatsapp.com/send?phone=" +
        (isLoading ? "0" : arrayData.cnfigs[0].WHATSAPP1)
      }
      class="whatsapp-button d-none d-md-block "
      target="_blank"
    >
      <img src={whatsapp} alt="WhatsApp" width="40" height="40" />
    </a>
  );
}; 
export default WhatsAppButton;
