import React, { useEffect, useState } from "react";
import logo from "../Assets/Isotipo.png"; 
import Loading from "../admin/Includes/Loading";
 
const StartLoad = ({ isLoged }) => {
  // const [logueado, setLogueado] = useState(true);

  return (
    <div className="d-flex justify-content-center  ling-items-center" style={{height:"100vh",   backgroundColor: "white"}}>
   
   <div className="align-self-center ">
   {<img src={logo} alt="asd" className="img-fluid mx-5" style={{maxWidth:"60px"}} />} 
   <Loading />
    </div>  
    </div>
  );
};

export default StartLoad;
