import { stringify } from "query-string";
import { getData, postdData, postUrl } from "../../Contextos/backend";
import validator from "validator";
import dayjs from "dayjs";
 
///////////////////
/* FUNCIONES GET */
///////////////////
export const getLista = async () => {
 
  let data = [];
  let queryParamsObj = { 
  };  
  const url = `sorteos/Listar`;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) { 
      data = res.data;
      resolve(data);
    } else {
      reject(res);
    }
  });
};
 

export const getInfoUsuario = async (id) => {
  let data = []; 
  const url = `sorteos/Consultar?id=`+id;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) { 
      data = JSON.parse(res.data.objRespuesta); 
      console.log(data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de este cliente",
        });
      } else {
        data = data[0];
        console.log(data);
        let Values = {
          id:  (data.ID),
          nombre:  (data.NOMBRE),  
          descripcion_sorteo:  (data.DESCRIPCION_SORTEO),
          fecha_fin:  (data.FECHA_FIN_DB),
          precio_boleto:  (data.PRECIO_BOLETO),
          folio_inicial:  (data.FOLIO_INICIAL),
          max_boletos:  (data.MAX_BOLETOS),


        }; 
        resolve(Values);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};
export const gteImagenes = async (id) => {
  let data = []; 
  const url = `sorteos/getImagenes?id=`+id;
  const res = await getData(url);
  return new Promise((resolve, reject) => {
    if (!res.error) { 
      data = JSON.parse(res.data.objRespuesta); 
      console.log(data);
      if (data.length == 0) {
        resolve({
          err: [],
          mensaje: "No se encontró información de este cliente",
        });
      } else {
        data = data[0];
        console.log(data);
        let Values = {
          id:  (data.ID==null?"":data.ID ),
          IMG1:  (data.IMG1==null?"":data.IMG1 ),
          IMG2: (data.IMG2==null?"":data.IMG2 ),
          IMG3: (data.IMG3==null?"":data.IMG3 ),
          BANNER:   (data.BANNER==null?"":data.BANNER ), 
          BOLETO:  (data.BOLETO==null?"":data.BOLETO ),  
        }; 
        resolve(Values);
      }
    } else {
      reject({
        mensaje: "Error al conectar a internet, revisa tu conexion a internet",
      });
    }
  });
};
////////////////////
/* FUNCIONES POST */
////////////////////
export const guardar = async (Values, Errores, setErrores, fecha) => {
  let error = false;

  /* REQUERIDOS */
  if (!Values.nombre || Values.nombre == "") {
    let Errores2 = Errores; 
    Errores2.nombre = true;
    setErrores(Errores2);
    error = true;
  }
  

  
 
  if (!Values.descripcion_sorteo || Values.descripcion_sorteo == "") {
    Errores.descripcion_sorteo = true;
    setErrores(Errores);
    error = true;
  }  



  if (!Values.precio_boleto || Values.precio_boleto == "") {
    Errores.precio_boleto = true;
    setErrores(Errores);
    error = true;
  } else {
    
  } 

   if (!Values.folio_inicial || Values.folio_inicial == "") {
    Errores.folio_inicial = true;
    setErrores(Errores);
    error = true;
  } else {
    
  }

   if (!Values.max_boletos || Values.max_boletos == "") {
    Errores.max_boletos = true;
    setErrores(Errores);
    error = true;
  } else {
     
  }
 
  Values.fecha_fin = fecha;

  if (!error) {
     
    let data = [];
    const body = Values;
    console.log("Datos", body);
    const url =
      Values.id == undefined || Values.id == "" || Values.id == 0
        ? "sorteos/Insertar"
        : "sorteos/Editar"; 
    const res = await postdData(url, body); 
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data; 
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};

export const Eliminar = async (id) => {
  if (id != undefined) {
    let data = []; 
    const url = `sorteos/Eliminar?id=`+id;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
}; 


 
export const Activo = async (id) => {
  if (id != undefined) {
    let data = []; 
    const url = `sorteos/updateActivo?id=`+id;
    const res = await postUrl(url);
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data;
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "No se pudo obtener el identificador" });
    });
  }
}; 

export const uploadImagenes = async ( img, tipo, id, extencion) => {
  let error = false;
 

   if (!tipo || tipo == "") {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "El tipo es requerido" });
    });
  }  


   if (!img || img == "") {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Selecciona una imagen" });
    });
  }  
 

  if (!error) {
     
    let data = [];
    const body = {
      img: img,
      tipo:tipo, 
      id_sorteo: id, 
      extencion: extencion
    };
    console.log("Datos", body);
    const url =  "sorteos/upImagen"; 
    const res = await postdData(url, body); 
    return new Promise((resolve, reject) => {
      if (!res.error) {
        data = res.data; 
        resolve(data);
      } else {
        reject(res);
      }
    });
  } else {
    return new Promise((resolve, reject) => {
      resolve({ mensaje: "Completa todos los campos requeridos" });
    });
  }
};
