import {
  Alert,
  Avatar,
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import * as React from "react";

import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import { useEffect } from "react";
import { getInfoBoletoDis } from "../Funciones/Sorteos";

const Buscador = ({ handleClick, activeButtons }) => {
  const [query, setQuery] = useState("");
  const [intervalo, setIntervalo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [ValueBusqueda, setValueBusqueda] = useState("");
  const [List, setList] = useState([]);
  // evento para detectar cuando se deja de escribir en el inoput
  const handleChangeBuscador = (event) => {
    clearInterval(intervalo);
    setQuery(event.target.value);
    let id = setInterval(function () {
      clearInterval(id);
      setValueBusqueda(event.target.value);
    }, 1000);
    setIntervalo(id);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      clearInterval(intervalo);
      setValueBusqueda(e.target.value);
    }
  };

  const getListaCliente = () => {
    setIsLoading(true);
    if (ValueBusqueda != "" && ValueBusqueda != undefined) {
      getInfoBoletoDis(ValueBusqueda)
        .then((resp) => {
          console.log(resp);
          let list = JSON.parse(resp.objRespuesta);
          setList(list);
          setIsLoading(false);
        })
        .catch((resp) => {
          setList([]);
          setIsLoading(false);
        });
    } else {
      setList([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getListaCliente();
  }, [ValueBusqueda]);

  return (
    <div
      className="contenedor-100 card shadow-lg border-0 p-4 pt-1 ms-0 ms-md-4 mb-4 h-100
  "
    >
      <div className="h-100">
      <h6 className="mt-2 text-center text-success">Encuentra tu boleto aquí</h6>
        <center>
          <Avatar
            className="mb-4 mt-3 shadow-lg"
            style={{
              background: "#7C8240",
              width: "40px",
              height: "40px",
            }}
          >
            <SearchIcon />
          </Avatar>
         
        </center>
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Buscar</InputLabel>
          <OutlinedInput
            id="Buscar"
            color="success"
            placeholder={"Buscar número"}
            value={query}
            onChange={handleChangeBuscador}
            onKeyDown={handleKeyDown}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            label="Buscar"
          />
        </FormControl>
        {List.length != 0 &&
        ValueBusqueda != "" &&
        ValueBusqueda != undefined &&
        !isLoading ? (
          <>
            <Alert className="mt-2" variant="standard" severity="success">
              El número esta disponible
            </Alert>
            <Button
            fullWidth
              onClick={() => {
                if (!activeButtons.includes(Number(List[0].FOLIO))) {
                  handleClick(Number(List[0].FOLIO));
                }
                setValueBusqueda("");
                setList([]);
                setQuery("");
              }}
              className="mt-2"
              variant="contained"
              style={{ backgroundColor: "#7C8240" }}
            >
              Añadir número
            </Button>
          </>
        ) : List.length == 0 &&
          ValueBusqueda != "" &&
          ValueBusqueda != undefined &&
          !isLoading ? (
          <Alert className="mt-2" variant="standard" severity="error">
            El número NO esta disponible
          </Alert>
        ) : (
          <> </>
        )}
        <center>
          {isLoading && <CircularProgress className="my-3" color="success" />}
        </center>
      </div>
    </div>
  );
};

export default Buscador;
