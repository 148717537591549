import Formas from "./Formas/Formas";
import Generales from "./Generales";
import Precios from "./Precios/Precios";

export default function Configs()
{



    return (
        <>
        
        <Generales />
        <Formas />
        <div className="w-100 mt-5"></div>
        <Precios />
        </>
    )
}