import { Button, Paper } from "@mui/material";
import Carrusel from "./Includes/Carrusel";
import zIndex from "@mui/material/styles/zIndex";
import { useContext } from "react";
import { AppContext } from "./Contextos/AppContext";
import { Link } from "react-router-dom";
import CuentaRegresiva from "./Includes/CuentaRegresiva";
import PreguntaasFrecuentes from "./Includes/PreguntaasFrecuentes";
import { useEffect } from "react";
import Contacto from "./Contacto";
import Footer from "./Includes/Footer";
import Redes from "./Includes/Redes";


export default function Inicio() {
  const { arrayData } = useContext(AppContext);

  useEffect(() => {
    const urlHasFragment = window.location.hash === "#preguntas";
    if (urlHasFragment) {
      const elementoPreguntas = document.getElementById("preguntas");
      if (elementoPreguntas) {
        const { top } = elementoPreguntas.getBoundingClientRect();
        window.scroll({
          top: window.pageYOffset + top,
          left: 0,
          behavior: "smooth",
        });
      }
    }
  }, []);

  return (
    <>
      <div
        className="position-absolute w-100 d-flex justify-content-center"
        style={{ zIndex: "99" }}
      >
        <Link to={"/comprarboleto"} style={{ textDecoration: "none" }}>
          <Button
            className="btn-animation  mt-5  d-none d-sm-block"
            color="success"
            variant="contained"
            size="large"
          >
            Compra tus boletos aquÍ
          </Button>
        </Link>
      </div>
      <Carrusel data={arrayData.sorteo} />

      <div className="p-4">
        <Link to={"/comprarboleto"} style={{ textDecoration: "none" }}>
          <Button
            className="btn-animation w-100 m-auto   d-block d-sm-none"
            color="success"
            variant="contained"
            size="large"
          >
            Compra tus boletos aquÍ
          </Button>
        </Link>
      </div>

      <Redes />

      <CuentaRegresiva fecha={arrayData.sorteo[0].FECHA_FIN} />

      <div className="p-4">
        <Link to={"/comprarboleto"} style={{ textDecoration: "none" }}>
          <Button
            className="btn-animation  m-auto  mt-5 mb-3 d-none d-sm-block"
            color="success"
            variant="contained"
            size="large"
          >
            Compra tus boletos aquÍ
          </Button>
        </Link>
      </div>

      <div className="container p-3 mt-4" style={{ maxWidth: "800px" }}>
        <h3 className="text-center mb-3">¿Quienes somos?</h3>
        <Paper className="shadow p-3">
          <div className="d-flex flex-column flex-sm-row justify-content-star justify-content-sm-star ">
            <div>
              <img
                src="https://solucionesfdev.blob.core.windows.net/sorteosrm/trabajo-en-equipo.png"
                className="img-fluid"
                style={{ maxWidth: "150px" }}
              />
            </div>
            <div className="align-self-center ps-3">
              <h5 className="text-muted mt-4 mt-sm-0">
                En nuestro equipo, nos consideramos más que colegas, somos
                amigos unidos por nuestra pasión por los sorteos y las emociones
                que generan.
              </h5>
            </div>
          </div>
        </Paper>

        <Paper className="shadow-lg p-3 mt-4">
          <div className="d-flex  flex-column flex-sm-row flex-column-reverse justify-content-center justify-content-sm-star">
            <div className="align-self-center pe-3">
              <h5 className="text-muted mt-4 mt-sm-0">
                Nuestro compromiso con la transparencia y la equidad son los
                pilares fundamentales para nosotros.
                <br />
                Valoramos la confianza que nuestros clientes depositan en
                nosotros y nos esforzamos por mejorar.
              </h5>
            </div>
            <div>
              <img
                src="https://solucionesfdev.blob.core.windows.net/sorteosrm/apreton-de-manos.png"
                className="img-fluid"
                style={{ maxWidth: "150px" }}
              />
            </div>
          </div>
        </Paper>
        <Paper className="shadow p-3 mt-4">
          <div className="d-flex  flex-column flex-sm-row  justify-content-star">
            <div>
              <img
                src="https://solucionesfdev.blob.core.windows.net/sorteosrm/maps-and-location.png"
                className="img-fluid"
                style={{ maxWidth: "150px" }}
              />
            </div>
            <div className="align-self-center ps-3">
              <h5 className="text-muted mt-4 mt-sm-0">
                Ayotlán, Jalisco es nuestro hogar y estamos orgullosos de formar
                parte de esta vibrante comunidad. Nos dedicamos a impulsar el
                entretenimiento y la diversión a nivel local.
              </h5>
            </div>
          </div>
        </Paper>
      </div>

      <PreguntaasFrecuentes /> 
      <div id="preguntas"></div>
      <div className="mx5 py-5" />
      <Contacto />
      <Footer />
    </>
  );
}
