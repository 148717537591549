import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import SearchIcon from "@mui/icons-material/Search"; 
import Row from "./Row";
import { Box, Button, CircularProgress, FormControl, InputAdornment, InputLabel, OutlinedInput, TableFooter, TablePagination, Tooltip } from "@mui/material";
 
import { getLista } from "../Funciones/Ventas";
import DialogoUsuario from "../Dialogos/DialogoVenta";
import { DatePickerInput } from "../../Includes/DatePicker";
import Select from "../../Includes/Select";

import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";


export default function BasicTable() {
  const [IsLoading, setIsLoading] = useState(true);
  const [Lista, setLista] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [fechaIn, setFechaIn] = useState(dayjs().subtract(7, "day"));
  const [fechaFin, setFechafin] = useState(dayjs().subtract(-1, "day"));
  const [tipoFiltro, settipofiltro] = useState("Folio");
  const [estatus, setEstatus] = useState("Todos");
  const [page, setPage] = React.useState(0); 
  const [rowsPerPage, setRowsPerPage] = React.useState(50); 
  const [query, setQuery] = useState("");
  const [intervalo, setIntervalo] = useState(""); 
  const [ValueBusqueda, setValueBusqueda] = useState("");

  const getListaAdmin = () => {
    setIsLoading(true);
    getLista(fechaIn, fechaFin,estatus,tipoFiltro,ValueBusqueda)
      .then((resp) => {
        let list = JSON.parse(resp.objRespuesta);
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };

  const handlInputChange = ({ target }) => {
    let Name = target.name;
    let Value = target.value ? target.value : "";
    if(Name=="estatus")
    {
      setEstatus(Value);
    }
     if(Name=="tipo")
    {
      settipofiltro(Value);
    }
  };

  const handleChangeBuscador = (event) => {
    clearInterval(intervalo);
    setQuery(event.target.value);
    let id = setInterval(function () {
      clearInterval(id);
      setValueBusqueda(event.target.value);
    }, 1000);
    setIntervalo(id);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      clearInterval(intervalo);
      setValueBusqueda(e.target.value);
    }
  };



  useEffect(() => {
    if (ValueBusqueda != "" && ValueBusqueda != undefined) 
    {    }
    getListaAdmin();

  }, [ValueBusqueda]);

  useEffect(() => {
    if (ValueBusqueda != "" && ValueBusqueda != undefined) 
    {
    getListaAdmin();
    }
  }, [tipoFiltro]);

  useEffect(() => { 
    getListaAdmin(); 
  }, [fechaFin, fechaIn, estatus]);
 
 

   

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

   

function TablePaginationActions({
  count,
  page,
  rowsPerPage,
  onPageChange,
  setShowModalEnviarFactura,
  showModalEnviarFactura,
}) {
  const theme = useTheme();

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

  return (
    <div>
      {openDialog && (
        <DialogoUsuario
          setOpen={setOpenDialog}
          id={0}
          getListaAdmin={getListaAdmin}
        />
      )}
      <div className="d-flex justify-content-star flex-wrap  w-100 mb-3">
        <div className="mt-3 me-0 me-sm-2" style={{ width: "170px" }}>
          <DatePickerInput
            size="small"
            name="fecha_fin"
            label="Fecha inicio"
            fecha={fechaIn}
            setFecha={setFechaIn}
          />
        </div>
        

        <div className="mt-3  me-0 me-sm-2" style={{ width: "170px" }}>
          <DatePickerInput
            size="small"
            name="fecha_fin"
            label="Fecha fin"
            fecha={fechaFin}
            setFecha={setFechafin}
          />
        </div>

        <div className="mt-3  me-0 me-sm-2" style={{ width: "170px" }}>
          <Select
            titulo="Estatus"
            size="small"
            Name="estatus"
            label="Estatus"
            ValueSelect={estatus}
            handlInputChange={handlInputChange}
            opciones={[
              { value: "Pagado", label: "Pagado" },
              { value: "Cancelado", label: "Cancelado" },
              { value: "Apartados", label: "Apartados" },
              { value: "Todos", label: "Todos" },
            ]}
          />
        </div>

        <div className="mt-3  me-0 me-sm-2" style={{ width: "170px" }}>
          <Select
            titulo="Buscar por"
            size="small"
            Name="tipo"
            label="Buscar por"
            ValueSelect={tipoFiltro}
            handlInputChange={handlInputChange}
            opciones={[
              { value: "Folio", label: "Folio de venta" },
              { value: "Boleto", label: "Boleto" },
              { value: "Nombre", label: "Nombre" },
              { value: "Telefono", label: "Teléfono" },
            ]}
          />
        </div>

  <div className="mt-3  me-0 me-sm-2" style={{ width: "200px" }}>
  <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Buscar</InputLabel>
          <OutlinedInput
          size="small"
            id="Buscar"
            color="success"
            placeholder={"Buscar"}
            value={query}
            onChange={handleChangeBuscador}
            onKeyDown={handleKeyDown}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            label="Buscar"
          />
        </FormControl>
        </div>


        <div>
          
        </div>
      </div>

      <Paper className="shadow border">
        <TableContainer>
          <Table size="small" aria-label="simple table">
            <TableHead className="bg-s" style={{ backgroundColor: "#e6e6e6" }}>
              <TableRow>
                <TableCell className="fw-bolder" align="left">
                  {" "}
                </TableCell>
                <TableCell className="fw-bolder" align="left">
                  Folio
                </TableCell>
                <TableCell
                  className="fw-bolder  d-none d-sm-table-cell "
                  align="left"
                >
                  Estatus
                </TableCell>

                <TableCell className="fw-bolder" align="left">
                  Nombre
                </TableCell>
                <TableCell
                  className="fw-bolder d-none d-sm-table-cell "
                  align="left"
                >
                  Contacto
                </TableCell>
                <TableCell
                  className="fw-bolder  d-none d-sm-table-cell"
                  align="left"
                ></TableCell>
                <TableCell
                  className="fw-bolder d-none d-sm-table-cell"
                  align="left"
                >
                  Fecha
                </TableCell>
                <TableCell className="fw-bolder " align="lerft">
                  Boletos
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {IsLoading ? (
                <TableCell colSpan={5}>
                  <div className="d-flex justify-content-center p-5 w-100">
                    <CircularProgress color="success" />
                    <span className="ms-3 align-self-center">Cargando...</span>
                  </div>
                </TableCell>
              ) : Lista.length == 0 ? (
                <TableCell colSpan={5}>
                  <div className="d-flex justify-content-center p-4 w-100">
                    <span className="ms-3 align-self-center">
                      Sin registros
                    </span>
                  </div>
                </TableCell>
              ) : (
                (rowsPerPage > 0
                  ? Lista.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : Lista
                ).map((row) => { 
                  return <Row row={row} getListaAdmin={getListaAdmin} />;
                })
              )}
              
            </TableBody> 
          </Table>

       

        </TableContainer>
        <div className="w-100 d-flex justify-content-end"> 
        <TablePagination
                rowsPerPageOptions={[
                  50,
                  100,
                  300,
                  { label: "Todos", value: -1 },
                ]}
                count={Lista.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "Filas por pagína",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={"Registros por página"}
                labelDisplayedRows={({ from, to, count, page }) => {
                  return `${from} - ${to} de ${count}`;
                }}
              />
                </div>
      </Paper>
    </div>
  );
}
